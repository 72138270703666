import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { popDialog, pushDialog } from "../../slices/dialogSlice";
import { useWorker } from "../../components/WorkerContext";
import { useCallback, useEffect, useRef, useState } from "react";
import { resetGame } from "../../slices/gameSlice";
import Lobby from "../../components/lobby/Lobby";
import _ from "lodash";
import TournamentBracketsView from "./TournamentBracketsView";
import Carousel from "react-material-ui-carousel";
import { findCurrentLevel } from "../../utils/tournamentUtils";
import { resetTournament } from "../../slices/tournamentSlice";
import { useNavigate } from "react-router-dom";
import { resetRoom } from "../../slices/roomSlice";
import ConfirmationDialog from "../../components/dialogs/ConfirmationDialog";

const createTriggerPulse = () => {
  let tId;
  const triggerPulse = (ref) => {
    if (ref.current) {
      ref.current.style.background = `rgba(0,0,223,0.3)`;
      clearTimeout(tId);
      tId = setTimeout(() => {
        ref.current.style.background = "rgba(0,0,0,0.3)";
      }, 500);
    }
  };
  return triggerPulse;
};

const triggerPulse = createTriggerPulse();

const TournamentLobbyPage = () => {
  const tournamentName = useSelector(({ tournament }) => tournament.id);
  const levels = useSelector(({ tournament }) => tournament.levels);
  const winner = useSelector(({ tournament }) => tournament.winner);
  const userName = useSelector(({ user }) => user.name);
  const [carouselIndex, setCarouselIndex] = useState(() =>
    findCurrentLevel(levels, userName)
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const maxLevel = levels?.length - 1;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socketWorker = useWorker();
  const nextMatch = useRef();
  const carouselBottom = useRef();

  const leaveRoom = () => {
    socketWorker.postMessage({
      type: "TOURNAMENT_LEAVE",
      payload: {
        tournamentName,
        userName,
      },
    });
    dispatch(resetTournament());
    navigate("/tournament", { replace: true });
  };

  const handleLeave = () => {
    if (!!winner) {
      leaveRoom()
    } else {
      setDialogOpen(true)
    }
  }

  useEffect(() => {
    let tId = setTimeout(() => {
      carouselBottom?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }, 300);
    return () => {
      clearTimeout(tId);
    };
  }, [carouselIndex]);

  useEffect(() => {
    dispatch(resetGame());
  }, []);

  return (
    <>
    <ConfirmationDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        onAccept={leaveRoom}
      >
        <Box>
          <Typography>
            Warning: Exiting the tournament will result in an instant loss. Do you still wish to exit?
          </Typography>
        </Box>
      </ConfirmationDialog>
      <Box display={"flex"} width={"100%"} justifyContent={"center"}>
        <Button
          variant="contained"
          sx={{ mt: 2, fontSize: "1.2rem" }}
          onClick={handleLeave}
        >
          LEAVE
        </Button>
      </Box>
      <TournamentBracketsView levels={levels} />
      <Box display={"flex"} width={"100%"} justifyContent={"center"}>
        <Button
          variant="bigfoot-outline"
          sx={{ mb: 2, fontSize: { md: "3rem", xs: "2rem" } }}
          onClick={() => {
            setCarouselIndex(findCurrentLevel(levels, userName));
            triggerPulse(nextMatch);
            carouselBottom?.current?.scrollIntoView({
              behavior: "smooth",
              block: "end",
            });
          }}
        >
          Next Round
        </Button>
      </Box>

      <Box>
        <Carousel
          sx={{
            mx: { md: 10, xs: 0 },
            backgroundColor: "rgba(0,0,0,0.15)",
            p: { md: 3, xs: 2 },
            borderRadius: "3rem",
          }}
          animation="slide"
          navButtonsAlwaysVisible
          cycleNavigation={false}
          autoPlay={false}
          indicators={false}
          index={carouselIndex}
          // NextIcon={ <h3 style={{writingMode: "vertical-lr", textOrientation: "upright"}}> {`${getLevelLabel(carouselIndex + 1, maxLevel)}`}</h3>}
          prev={(prev) => setCarouselIndex(prev)}
          next={(next) => setCarouselIndex(next)}
        >
          {levels.map((level, levelIndex) => {
            const isNextMatch =
              levelIndex === findCurrentLevel(levels, userName);
            return (
              <Box
                ref={isNextMatch ? nextMatch : null}
                sx={{
                  backgroundColor: "rgba(0,0,0,0.3)",
                  borderRadius: "3rem",
                  maxWidth: 500,
                  margin: "auto",
                  transition: "background 0.3s ease-in-out",
                }}
              >
                <TournamentLevel
                  key={`level_${levelIndex}`}
                  level={level}
                  levelIndex={levelIndex}
                  maxLevel={maxLevel}
                />
              </Box>
            );
          })}
        </Carousel>
        <Box ref={carouselBottom} minHeight={"10vh"} />
      </Box>
    </>
  );
};

const TournamentLevel = ({ level, levelIndex, maxLevel, nextMatch }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const userName = useSelector(({ user }) => user.name);
  const isNextMatch = !!level.find(
    (bracket) => !bracket.finished && bracket?.users?.[userName]
  );
  const hidePlayers = mobile && isNextMatch;
  const lobbyTable = {
    tableKey: "room",
    header: {
      items: [
        { key: "header_highlight", value: "" },
        { key: "header_match", value: "Match" },
        ...(!hidePlayers ? [{ key: "header_players", value: "Players" }] : []),
        { key: "header_empty", value: "" },
      ],
    },
    body: {
      items: [
        {
          key: "highlight",
          type: "component",
          component: (item) =>
            isNextMatch && item?.users?.[userName] ? (
              <Typography variant="h6" align="center">
                ⭐️
              </Typography>
            ) : null,
        },
        {
          key: "room",
          sx: {
            fontWeight: "bold",
          },
        },
        ...(!hidePlayers
          ? [
              {
                key: "users",
                type: "component",
                component: (item) => {
                  const usersJoined = _.size(
                    Object.values(item?.users ?? {}).filter(
                      (user) => user.joined
                    )
                  );
                  return `${usersJoined}/2`;
                },
              },
            ]
          : []),
        {
          key: "actions",
          type: "component",
          align: "right",
          component: (item) => <BracketAction bracket={item} />,
        },
      ],
    },
  };

  return (
    <Box>
      <Lobby
        lobbyActions={[]}
        lobbyTableTitle={
          levelIndex === maxLevel
            ? "Finals"
            : levelIndex === maxLevel - 1
            ? "Semi finals"
            : levelIndex === maxLevel - 2
            ? "Quarter finals"
            : levelIndex === maxLevel - 3
            ? "Round of 16"
            : `Level ${levelIndex}`
        }
        TableFallbackComponent={<h1> placeholder</h1>}
        lobbyList={level}
        lobbyTable={lobbyTable}
      />
    </Box>
  );
};

const BracketAction = ({ bracket }) => {
  const tournamentName = useSelector(({ tournament }) => tournament.id);
  const roomName = bracket.room;
  const userName = useSelector(({ user }) => user.name);
  const isCurrentRoom = !!bracket?.users?.[userName];
  const socketWorker = useWorker();
  const dispatch = useDispatch();
  const titleBuilder = (tournamentName) => `Tournament ${tournamentName}`;

  const leaveRoom = useCallback(() => {
    socketWorker.postMessage({
      type: "ROOM_LEAVE",
      payload: { roomName, userName },
    });
    dispatch(resetRoom());
  }, [dispatch, roomName, socketWorker, userName]);

  const openPlayersReadyDialog = () => {
    dispatch(popDialog());
    dispatch(
      pushDialog({
        id: "ROOM_PLAYERS_READY",
        dialogProps: {
          fullWidth: true,
        },
        options: { disableBackdropClick: true, disableEscapeKey: true },
      })
    );
  };

  const joinRoom = () => {
    socketWorker.postMessage({
      type: "TOURNAMENT_JOIN_ROOM",
      payload: {
        tournamentName,
        roomName,
      },
    });
    dispatch(
      pushDialog({
        id: "MATCH_WAIT_LIST",
        props: {
          matchName: roomName,
          titleBuilder,
          onExit: leaveRoom,
          onPlayersReady: openPlayersReadyDialog,
          selector: ({ room }) => room,
          mapper: {
            users: "users",
            maxUsers: "options.maxUsers",
          },
          options: {
            hideInvite: true,
          },
        },
        dialogProps: {
          fullWidth: true,
        },
        options: { disableBackdropClick: true, disableEscapeKey: true },
      })
    );
  };

  return isCurrentRoom ? (
    <Button
      variant={bracket?.finished ? "outlined" : "bigfoot"}
      onClick={joinRoom}
      disabled={bracket?.finished}
      sx={{ fontSize: "1rem" }}
    >
      {bracket?.finished ? "Finished" : "Ready"}
    </Button>
  ) : null;
};

export default TournamentLobbyPage;
