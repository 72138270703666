import { cardPairsOptions } from "./gameOptions";
import { maxUsersOptions } from "./tournamentOptions";

const tournamentCreateBuilder = (settings) => ({
  options: [
    {
      key: "maxUsers",
      type: "select",
      label: "Players",
      variant: "bigfoot",
      labelVariant: "bigfoot",
      options: maxUsersOptions,
      default: 4,
    },
    {
      key: "maxCardPairs",
      type: "select",
      variant: "bigfoot",
      labelVariant: "bigfoot",
      label: "Pairs",
      options: cardPairsOptions,
      default: 8,
    },
    {
      key: "create",
      type: "button",
      actionType: "submit",
      label: "START!",
      variant: "bigfoot",
      sx: { mt: "1.5rem" },
    },
  ],
  advancedOptions: [
    {
      type: "group",
      sx: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      },
      items: [
        {
          key: "enemyReveal",
          sx: { mt: "1.5rem" },
          boxSx: {
            justifyContent: "center",
          },
          active: {
            boxSx: { color: "text.primary" },
            typographySx: { fontWeight: 300 },
          },
          inactive: {
            boxSx: { color: "gray" },
          },
          typographySx: {
            fontSize: 24,
          },
          type: "switch",
          label: "Reveal",
          default: true,
        },
      ],
    },
    {
      key: "create",
      type: "button",
      actionType: "custom",
      label: "Cancel",
      onClick: settings?.toggleAdvancedOptions,
      variant: "bigfoot-outline",
      sx: { mt: "1.5rem" },
    },
  ],
});

export default tournamentCreateBuilder;
