import { useDispatch, useSelector } from "react-redux"
import { incrementDataRevision } from "../slices/revisionSlice"
import { changeAvatar } from "../services/profileServices"
import { useState } from "react"

const GalleryImage = ({ url, id }) => {
  const dispatch = useDispatch()
  const profileId = useSelector(({ user }) => user.profileId)
  const [hover, setHover] = useState(false)

  const handleImageClick = async () => {
    await changeAvatar(profileId, { url })
    dispatch(incrementDataRevision({ event: "userRevision" }))
  }

  return <img src={url.replace("1024", "256")} alt={id} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} style={{
    borderRadius: "100%", width: "100px",...( hover ? { boxShadow: "0px 0px 15px 5px cyan", transform: "scale(125%)", transition: "transform 0.2s ease-in-out"} : {})
  }} onClick={handleImageClick} />
}

export default GalleryImage