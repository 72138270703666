import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "@mui/material";
import registry from "./dialogRegistry";
import { popDialog } from "../slices/dialogSlice";

const DialogStack = () => {
  const dialogStack = useSelector((state) => state.dialog.stack);
  const dispatch = useDispatch();
  const childRef = useRef(null);

  const createCloseHandler = (options, index) => (event, reason) => {
    if (options.disableBackdropClick && reason === "backdropClick") return;
    if (options.disableEscapeKey && reason === "escapeKeyDown") return;

    // Execute custom logic if defined in the child component
    if (childRef.current && index === dialogStack.length - 1) {
      childRef.current.executeCustomCloseLogic?.(event, reason);
    }

    dispatch(popDialog());
  };

  return (
    <>
      {dialogStack.map((dialog, index) => {
        const Component = registry[dialog.id] ?? DefaultDialog;
        return (
          <Dialog
            key={index}
            open={index === dialogStack.length - 1}
            onClose={createCloseHandler(dialog.options ?? {}, index)}
            {...(dialog.dialogProps ?? {})}
          >
            <Component
              ref={index === dialogStack.length - 1 ? childRef : null}
              {...(dialog.props ?? {})}
            />
          </Dialog>
        );
      })}
    </>
  );
};

const DefaultDialog = () => {
  return <h1> No content </h1>;
};

export default DialogStack;
