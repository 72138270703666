import { Box } from "@mui/material";

const FormGroup = ({ hidden, children, sx }) => {
  return (
    <Box display={hidden ? "none" : "flex"} flexDirection={"column"} sx={sx}>
      {children}
    </Box>
  );
};

export default FormGroup;
