import { Box } from "@mui/material"
import { getFeedbackList } from "../services/feedbackServices"
import { useEffect, useState } from "react"
import FeedbackItem from "../components/feedback/FeedbackItem"
import { useSelector } from "react-redux"
import { useAutoAnimate } from "@formkit/auto-animate/react"
import { useWorker } from "../components/WorkerContext"

const useFeedback = () => {
  const [feedbacks, setFeedbacks] = useState(null)
  const feedbackRevision = useSelector(({ revision }) => revision.feedbackRevision)
  const socketWorker = useWorker()

  const getFeedbacks = async () => {
    try {
      const feedbackResponse = await getFeedbackList()
      setFeedbacks(feedbackResponse)
    } catch (err) {
      setFeedbacks(null)
    }
  }

  useEffect(() => {
    socketWorker.postMessage({ type: "FEEDBACK_CONNECT" })
  }, [socketWorker])

  useEffect(() => {
    getFeedbacks()
  }, [feedbackRevision])

  return feedbacks
}

const getVoteCount = (feedback) => (feedback?.upvotes?.length ?? 0) - (feedback?.downvotes?.length ?? 0)
const sortfeedbacks = (f1, f2) => getVoteCount(f2) - getVoteCount(f1)

const FeedbackBoard = () => {

  const feedbacks = useFeedback()

  const [listRef] = useAutoAnimate({
    duration: 300,
    easing: "ease"
  })

  return <Box pb={10} ref={listRef} height={"100%"}>
    {feedbacks?.sort(sortfeedbacks)?.map(feedback => <FeedbackItem key={feedback?._id} feedbackId={feedback?._id} {...(feedback ?? {})} />)}
  </Box>

}

export default FeedbackBoard