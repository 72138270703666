import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWorker } from "../../components/WorkerContext";
import { setDeathmatchFilter } from "../../slices/optionsSlice";
import MatchSetup from "../../components/matchSetup/MatchSetup";
import roomCreateBuilder from "../../components/form/config/roomCreateBuilder";
import { popDialog, pushDialog } from "../../slices/dialogSlice";
import { resetRoom } from "../../slices/roomSlice";
import tournamentCreateBuilder from "../../components/form/config/tournamentCreateBuilder";
import { useNavigate } from "react-router-dom";
import { resetTournament } from "../../slices/tournamentSlice";

const generateRandomTournamentName = () => {
  const randomNumber = Math.round(Math.random() * 10000);
  return `T${randomNumber}`;
};

const TournamentCreatePage = () => {
  const [tournamentName] = useState(generateRandomTournamentName);
  const socketWorker = useWorker();
  const userName = useSelector(({ user }) => user.name);
  const profileId = useSelector(({ user }) => user.profileId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const titleBuilder = (tournamentName) => `Tournament ${tournamentName}`;

  const leaveTournament = () => {
    socketWorker.postMessage({
      type: "TOURNAMENT_LEAVE",
      payload: { tournamentName, userName },
    });
    dispatch(resetTournament());
  };

  const redirectToTournamentLobby = () => {
    dispatch(popDialog())
    navigate(`/tournament/${tournamentName}/lobby`)
  }

  const handleSubmit = (model) => {
    const { maxUsers, maxCardPairs, enemyReveal } = model;
    socketWorker.postMessage({
      type: "TOURNAMENT_CREATE",
      payload: {
        tournamentName,
        userName,
        profileId,
        options: { maxUsers, maxCardPairs, enemyReveal },
      },
    });
    dispatch(
      pushDialog({
        id: "MATCH_WAIT_LIST",
        props: {
          matchName: tournamentName,
          fromTournament: true,
          titleBuilder,
          onExit: leaveTournament,
          onPlayersReady: redirectToTournamentLobby,
          selector: ({ tournament }) => tournament,
          mapper: {
            users: "users",
            maxUsers: "options.maxUsers",
          },
          options: {
            hideInvite: true,
          },
        },
        dialogProps: {
          fullWidth: true,
        },
        options: { disableBackdropClick: true, disableEscapeKey: true },
      })
    );
  };

  return (
    <MatchSetup
      titleBuilder={titleBuilder}
      configBuilder={tournamentCreateBuilder}
      matchName={tournamentName}
      onSubmit={handleSubmit}
    />
  );
};

export default TournamentCreatePage;
