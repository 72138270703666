import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Icon } from '@mui/material';
import { formatCurrency } from '../utils/stringUtils';

const CoinCounter = ({
  initialCount,
  targetCount,
  onCountChange,
  variant = "coins",
  countChangeInterval = 1,
  targetStopSpeed = 200, //ms
  differenceOnly = false
}) => {
  const [count, setCount] = useState(initialCount || targetCount);
  const lastTargetCount = useRef(targetCount)
  const difference = useRef(targetCount)

  useEffect(() => {
    difference.current = Math.abs(targetCount - lastTargetCount.current)
    lastTargetCount.current = targetCount
  }, [targetCount])

  useEffect(() => {
    const shouldIncrement = targetCount > count;
    const shouldDecrement = targetCount < count;
    const shouldUpdate = shouldIncrement || shouldDecrement;  
    const coinNumbers =  Math.min((targetStopSpeed)/5, Math.ceil(difference.current * 0.7))
    const lastCoins = Math.abs(targetCount - count) <= coinNumbers
    const lastFrameCount = lastCoins ? coinNumbers - Math.abs(targetCount - count) : 0


    if (shouldUpdate) {
      setTimeout(() => {
        setCount(count + (shouldIncrement ? 1 : -1));
        onCountChange && onCountChange(count + (shouldIncrement ? 1 : -1));
        // Add sound effect here
      }, countChangeInterval + (targetStopSpeed * ((lastFrameCount ** 4) / coinNumbers ** 4)));
    }
  }, [count, targetCount, onCountChange, countChangeInterval, targetStopSpeed]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
      <Icon sx={{ p: 0, color: "gold" }}>paid</Icon>
      {variant === "coins" ?
        <Typography variant="h5" sx={{ color: "gold" }}>{` ${ !differenceOnly ? formatCurrency(count || 0) : formatCurrency(difference.current || 0)}`}</Typography>
        : variant === "static" &&
        <Typography variant="h5" sx={{ color: "gold" }}>{targetCount}</Typography>
      }
    </Box>
  );
};

export default CoinCounter;
