import { createSlice } from "@reduxjs/toolkit";

export const roomInitialState = {
  id: "",
  users: {},
  status: "",
  options: {
    maxUsers: null,
    maxCardPairs: null,
    enemyReveal: null,
    practice: null,
    deathmatch: null,
  },
  botOptions: {
    speed: null,
    memorySize: null,
    retentionTime: null,
  },
  playersWaiting: true,
  roundCount: 0,
  tally: {},
  games: {},
  tournamentRoom: false,
};

const roomSlice = createSlice({
  name: "room",
  initialState: roomInitialState,
  reducers: {
    updateRoom: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetRoom: () => {
      return roomInitialState;
    },
    setPlayersWaiting: (state, action) => {
      const playersWaiting = action.payload;
      state.playersWaiting = playersWaiting;
    },
  },
});

export const roomReducer = roomSlice.reducer;
export const { updateRoom, resetRoom, setPlayersWaiting } = roomSlice.actions;
export default roomSlice;
