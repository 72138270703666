import { Alert, Box, Container, Typography } from "@mui/material";
import { useState } from "react";
import MatchHeader from "./MatchHeader";
import MatchBody from "./MatchBody";
import { useWorkerListener } from "../WorkerContext";
import { useDispatch } from "react-redux";
import { popDialog } from "../../slices/dialogSlice";

const MatchSetup = ({ configBuilder, headerTitle, matchName, titleBuilder, onSubmit, onChange }) => {
  const [isInAdvancedOptions, setIsInAdvancedOptions] = useState(false);
  const [error, setError] = useState(null);

  const toggleAdvancedOptions = () => setIsInAdvancedOptions((a) => !a);
  const config = configBuilder?.({ toggleAdvancedOptions });
  const dispatch = useDispatch()

  const showAdvancedOptions = !!config?.advancedOptions;

  useWorkerListener((event) => {
    switch (event.data.type) {
      case "TOURNAMENT_ERROR":
        setError(event.data.payload);
        dispatch(popDialog())
        break;
      default:
        break;
    }
  });

  return (
    <Container maxWidth="sm">
      <Box p={5} display="flex" flexDirection={"column"} gap={"1rem"}>
        <MatchHeader
          headerTitle={headerTitle}
          matchName={matchName}
          titleBuilder={titleBuilder}
          GearComponent={
            showAdvancedOptions ? (
              <GearComponent
                isInAdvancedOptions={isInAdvancedOptions}
                toggleAdvancedOptions={toggleAdvancedOptions}
              />
            ) : null
          }
        />
        <MatchBody
          config={config}
          onSubmit={onSubmit}
          onChange={onChange}
          isInAdvancedOptions={isInAdvancedOptions}
        />
        {error && (
          <Alert severity="warning">
            <Typography>{error}</Typography>
          </Alert>
        )}
      </Box>
    </Container>
  );
};

const GearComponent = ({ isInAdvancedOptions, toggleAdvancedOptions }) => {
  return (
    <Typography
      align="center"
      sx={{
        p: 0,
        fontSize: "2rem",
        transform: `scale(${isInAdvancedOptions ? "1.3" : "1"})`,
        transition: "transform ease-in 0.1s",
      }}
      onClick={toggleAdvancedOptions}
    >
      ⚙️
    </Typography>
  );
};

export default MatchSetup;
