// mui
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// utils
import { generateLegendImageComponent } from "./utils/legend-utils";
import { useMediaQuery, useTheme } from "@mui/material";

const LegendImage = generateLegendImageComponent("subscription");

// const useStyles = makeStyles((theme) => ({
// 	text: {
// 		color: theme.palette.text.primary,
// 	},
// 	image: { boxSizing: "border-box", maxHeight: "30px" },
// }));

const LegendSummary = ({ icon, svg: svgElement, imgSrc, label }) => {
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down("sm"))

	return (
		<Grid container>
			<Grid item container xs={3} alignContent="center" justify="center">
				<Box marginX={mobile ? 0 : 2}>
					{svgElement ?? null}
					{icon && <Icon> {icon} </Icon>}
					{imgSrc && <LegendImage fileName={imgSrc} alt={label} imgProps={{ height: "50px", width: "50px" }} />}
				</Box>
			</Grid>
			<Grid item container xs={9} alignContent={"center"} >
				<Typography  color="text.primary">
					{label}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default LegendSummary;
