import axios from "axios";
const SERVER_NAME = process.env.REACT_APP_WEBSOCKET_URL;

export const axiosConfig = {
	SERVER_NAME: SERVER_NAME,
	HEADERS: {
		"Content-Type": "application/json",
	},
};

export const createRequest = (timeout) => {
	return axios.create({
		baseURL: axiosConfig.SERVER_NAME,
		headers: axiosConfig.HEADERS,
		timeout: timeout,
	});
};