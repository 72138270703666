import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import ReactFastClick from "react-fastclick";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material";
import store from "./reducer/store";
import AppRouter from "./components/AppRouter";
import Auth from "./components/Auth";
import { WorkerProvider } from "./components/WorkerContext";
import SnackbarStack from "./components/snackbars/SnackbarStack";
import DialogStack from "./dialog/DialogStack";
import theme from "./theme";
import IntroVideo from "./components/IntroVideo";
import VideoBackground from "./components/VideoBackground";
import ErrorBoundary from "./components/ErrorBoundary";
import { createContext, useEffect } from "react";
import { initializeApp } from "@firebase/app";
import { getAuth, getRedirectResult } from "firebase/auth";
import SocketMessages from "./socket/SocketMessages";
import OuterErrorBoundary from "./components/OuterErrorBoundary";
import GlobalListener from "./socket/GlobalListener";

// FIREBASE
const firebaseConfig = {
  apiKey: "AIzaSyBagVATTXBLmftzb0ABKF_gF9cCcgz7Usg",
  authDomain: "project.mw",
  // authDomain: "projectmw-3b3aa.firebaseapp.com",
  projectId: "projectmw-3b3aa",
  storageBucket: "projectmw-3b3aa.appspot.com",
  messagingSenderId: "885944446445",
  appId: "1:885944446445:web:04c0e7a470d75ced6d8be4",
  measurementId: "G-GEJNKX64S7",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const reactContext = createContext();

const queryClient = new QueryClient();

ReactFastClick();

let darkTheme = createTheme(theme);
darkTheme = responsiveFontSizes(darkTheme);

function App() {
  useEffect(() => {
    getRedirectResult(auth)
      .then((result) => {
        if (result?.user) {
          console.log("User signed in:", result?.user);
          // Do something with the logged-in user's data.
        }
      })
      .catch((error) => {
        console.error("Error from redirect:", error);
      });
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  return (
    <OuterErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <reactContext.Provider value={{ auth }}>
            <WorkerProvider>
              <Router>
                <Auth>
                  <ThemeProvider theme={darkTheme}>
                    <SnackbarProvider
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    >
                      <IntroVideo>
                        <VideoBackground>
                          <ErrorBoundary>
                          <AppRouter />
                          <DialogStack />
                          <SnackbarStack />
                          <SocketMessages />
                          <GlobalListener /> 
                          </ErrorBoundary>
                        </VideoBackground>
                      </IntroVideo>
                    </SnackbarProvider>
                  </ThemeProvider>
                </Auth>
              </Router>
            </WorkerProvider>
          </reactContext.Provider>
        </Provider>
      </QueryClientProvider>
    </OuterErrorBoundary>
  );
}

export default App;
