import {
  Avatar,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { isBase64 } from "../../utils/stringUtils";
import _ from "lodash";
import { getTransitionSizes } from "@formkit/auto-animate";
import InviteButton from "./InviteButton";

const UserDisplay = ({ user }) => {
  const { name, avatar } = user ?? {};

  return user ? (
    <Avatar
      alt={name}
      src={isBase64(avatar) ? `data:image/png;base64,${avatar}` : avatar}
      style={{ height: "5rem", width: "5rem" }}
    />
  ) : (
    <div
      style={{
        border: "2px solid rgba(255,255,255,0.5)",
        borderRadius: "100%",
        height: "5rem",
        width: "5rem",
      }}
    />
  );
};

const autoAnimatePlugin = (el, action, oldCoords, newCoords) => {
  let keyframes;
  // supply a different set of keyframes for each action
  if (action === "add") {
    keyframes = [
      { transform: "scale(0)", opacity: 0 },
      { transform: "scale(1.15)", opacity: 1, offset: 0.75 },
      { transform: "scale(1)", opacity: 1 },
    ];
  }
  // keyframes can have as many "steps" as you prefer
  // and you can use the 'offset' key to tune the timing
  if (action === "remove") {
    keyframes = [
      { transform: "scale(1)", opacity: 1 },
      { transform: "scale(1.15)", opacity: 1, offset: 0.33 },
      { transform: "scale(0.75)", opacity: 0.1, offset: 0.5 },
      { transform: "scale(0.5)", opacity: 0 },
    ];
  }
  if (action === "remain") {
    // for items that remain, calculate the delta
    // from their old position to their new position
    const deltaX = oldCoords.left - newCoords.left;
    const deltaY = oldCoords.top - newCoords.top;
    // use the getTransitionSizes() helper function to
    // get the old and new widths of the elements
    const [widthFrom, widthTo, heightFrom, heightTo] = getTransitionSizes(
      el,
      oldCoords,
      newCoords
    );
    // set up our steps with our positioning keyframes
    const start = { transform: `translate(${deltaX}px, ${deltaY}px)` };
    const mid = {
      transform: `translate(${deltaX * -0.15}px, ${deltaY * -0.15}px)`,
      offset: 0.75,
    };
    const end = { transform: `translate(0, 0)` };
    // if the dimensions changed, animate them too.
    if (widthFrom !== widthTo) {
      start.width = `${widthFrom}px`;
      mid.width = `${widthFrom >= widthTo ? widthTo / 1.05 : widthTo * 1.05}px`;
      end.width = `${widthTo}px`;
    }
    if (heightFrom !== heightTo) {
      start.height = `${heightFrom}px`;
      mid.height = `${
        heightFrom >= heightTo ? heightTo / 1.05 : heightTo * 1.05
      }px`;
      end.height = `${heightTo}px`;
    }
    keyframes = [start, mid, end];
  }
  // return our KeyframeEffect() and pass
  // it the chosen keyframes.
  return new KeyframeEffect(el, keyframes, {
    duration: 600,
    easing: "ease-out",
  });
};

const WaitingView = ({ matchName, users, maxUsers, hideInvite }) => {
  const urlBuilder = (matchName, inviteCode) =>
    `${window.location.origin}/room/join/${matchName}?ref=${inviteCode}`;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const currentUsers = _.size(users);
  const usersArray = Object.values(users ?? {});

  const missingPlayers = maxUsers - currentUsers;

  const [listRef] = useAutoAnimate(autoAnimatePlugin);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      p={4}
      gap={1}
    >
      {!hideInvite && <img
        src="/assets/img/megaphone-7296631-6167168.png"
        width={`${isMobile ? 15 : 20}0px`}
        height={`${isMobile ? 15 : 20}0px`}
        alt=""
      />}
      <Box
        display={"flex"}
        width={"90%"}
        p={2}
        bgcolor={"rgba(0,0,0,0.3)"}
        borderRadius={2}
        gap={1}
        flexWrap={"wrap"}
        justifyContent={"space-around"}
        ref={listRef}
      >
        {new Array(maxUsers).fill().map((e, index) => (
          <UserDisplay key={usersArray[index]?.name} user={usersArray[index]} />
        ))}
      </Box>
      {missingPlayers >= 2 ? (
        <Typography variant={`h${isMobile ? 5 : 4}`} align="center">
          You need people
        </Typography>
      ) : missingPlayers > 0 ? (
        <Typography variant={`h${isMobile ? 6 : 5}`} align="center">
          Only one more!
        </Typography>
      ) : (
        <Typography variant={`h${isMobile ? 6 : 6}`} align="center">
          Full house!
        </Typography>
      )}
      {matchName && !hideInvite && (
        <InviteButton matchName={matchName} urlBuilder={urlBuilder} />
      )}
    </Box>
  );
};

export default WaitingView;
