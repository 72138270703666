// src/WorkerContext.js

import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import SocketWorker from "../workers/socket.worker";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { connect, disconnect, pushMessage } from "../slices/connectionSlice";

const WorkerContext = createContext(null);
const isDevelopment = process.env.NODE_ENV === "development";

export const useWorker = () => {
  return useContext(WorkerContext);
};

export const useWorkerListener = (callback) => {
  const worker = useWorker();
  const [listenerId, setListenerId] = useState(null);

  useEffect(() => {
    if (worker && typeof callback === "function" && !listenerId) {
      const id = worker.addListener(callback);
      setListenerId(id);
    }
    return () => {
      if (worker && listenerId) {
        worker.removeListener(listenerId);
      }
    };
    //eslint-disable-next-line
  }, [worker, listenerId]);

  return listenerId
};

export const WorkerProvider = ({ children }) => {
  const workerRef = useRef(null);
  const [worker, setWorker] = useState(null);
  const listenersRef = useRef({});
  const isAdmin = useSelector(({ user }) => user.isAdmin)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!workerRef.current) {

      workerRef.current = new SocketWorker();

      workerRef.current.postMessage({
        type: "init",
        websocketUrl: !isDevelopment
          ? process.env.REACT_APP_WEBSOCKET_URL
          : `http://${window.location.hostname}:3001`,
      });

      setWorker(workerRef.current);
    }

    const handleMessage = (event) => {
      if (event.data.type === "CONNECTED") {
        dispatch(connect());
      } else if(event.data.type === "DISCONNECTED"){
        dispatch(disconnect());
      }
      if (isAdmin) {
        dispatch(pushMessage(JSON.stringify(event.data)))
      }
      Object.values(listenersRef.current).forEach((listener) =>      
        listener(event)
      );
    };

    workerRef.current.addEventListener("message", handleMessage);

    return () => {
      workerRef.current.removeEventListener("message", handleMessage);

    };
  }, [dispatch, isAdmin]);

  useEffect(() => {
    return () => {
      workerRef.current.terminate();
      workerRef.current = null;
      setWorker(null);
    }
    //eslint-disable-next-line
  }, [])

  workerRef.current &&
    (workerRef.current.addListener = (callback) => {
      const id = uuidv4();
      listenersRef.current[id] = callback;
      return id;
    });

  workerRef.current &&
    (workerRef.current.removeListener = (id) => {
      delete listenersRef.current[id];
    });

  return (
    <WorkerContext.Provider value={worker}>{children}</WorkerContext.Provider>
  );
};
