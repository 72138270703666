import { Avatar, Box, Card, CardContent, Icon, IconButton, Paper, Typography } from "@mui/material"
import { buildSrc } from "../../utils/stringUtils"
import { formatName } from "../../utils/stringUtils"
import { useSelector } from "react-redux"
import { downvoteFeedback, upvoteFeedback } from "../../services/feedbackServices"
import { adjectives, uniqueNamesGenerator } from "unique-names-generator"
import _ from "lodash"
import { useRef } from "react"
import { useWorker } from "../WorkerContext"
import FeedbackChip from "./FeedbackChip"
import moment from "moment"

// const calculateVotes = (up, down) => Math.max(up?.length - down?.length, 0)
const calculateVotes = (up, down) => up?.length - down?.length

const getRandomAdjective = () => {
  const name = uniqueNamesGenerator({
    dictionaries: [adjectives],
    length: 1,
  });

  return name
}

const formatDate = (date) => moment(date).fromNow()

const FeedbackVotes = ({ upvotes, downvotes, feedbackId }) => {

  const profileId = useSelector(({ user }) => user.profileId)
  const upVoted = upvotes.includes(profileId)
  const downVoted = downvotes.includes(profileId)

  const upVoteColor = "#8afc00"
  const downVoteColor = "#fc8a00"

  const socketWorker = useWorker()

  const handleUpvote = async () => {
    await upvoteFeedback(feedbackId, profileId)
    socketWorker.postMessage({ type: "ROOM_TRIGGER_REVISION", payload: { roomName: "feedbackRoom", revision: "feedbackRevision" } });

    // dispatch(incrementDataRevision({ event: "feedbackRevision" }));
  }
  
  const handleDownvote = async () => {
    await downvoteFeedback(feedbackId, profileId)
    socketWorker.postMessage({ type: "ROOM_TRIGGER_REVISION", payload: { roomName: "feedbackRoom", revision: "feedbackRevision" } });

    // dispatch(incrementDataRevision({ event: "feedbackRevision" }));
  }

  return <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
    <IconButton disabled={upVoted} onClick={handleUpvote}>
      <Icon sx={[{ color: "white", "&:hover": { color: upVoteColor } }, upVoted && { color: upVoteColor }]}>
        keyboard_arrow_up
      </Icon>
    </IconButton>
    <Typography align="center">
      {calculateVotes(upvotes, downvotes)}
    </Typography>
    <IconButton disabled={downVoted} onClick={handleDownvote}>
      <Icon sx={[{ color: "white", "&:hover": { color: downVoteColor } }, downVoted && { color: downVoteColor }]}>
        keyboard_arrow_down
      </Icon>
    </IconButton>
  </Box>
}

const FeedbackContent = ({ anonymous, profile, title, description, type, createdAt }) => {
  const ninjaSrc = "/assets/img/verification/ninja-6853235-5625761.png"
  const ninjaAdjective = useRef(_.capitalize(getRandomAdjective()))

  return <Box display={"flex"} flexDirection={"column"} width={"100%"}>
    <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={2}>
      <Avatar
        alt={anonymous ? "ninja" : profile?.username}
        src={anonymous ? ninjaSrc : buildSrc(profile?.image, profile?.imgB64)}
      />
      <Typography >
        {anonymous ? `${ninjaAdjective.current} Ninja` : profile?.username ? formatName(profile.username) : ""}
      </Typography>
      <Typography variant="caption">

      {formatDate(createdAt)}
      </Typography>
    </Box>
    <Box display={"flex"}>

    <Typography>
      {title}
    </Typography>
      <FeedbackChip type={type}/>
    </Box>
    <Card variant="outlined" sx={{ mt: 1 }}>
      <CardContent sx={{ width: "auto" }}>
        <Typography>
          {description}
        </Typography>
      </CardContent>
    </Card>
  </Box>
}

const FeedbackItem = ({ feedbackId, anonymous, profile, title, description, type, upvotes, downvotes, createdAt }) => {
  return <Paper sx={{ backgroundColor: (theme) => theme.palette.background.default, mx: 4, my: 2, p: 1 }}>
    <Box display={"flex"} flexDirection={"row"} gap={2} m={2}>

      <FeedbackVotes feedbackId={feedbackId} upvotes={upvotes} downvotes={downvotes} />
      <FeedbackContent anonymous={anonymous} profile={profile} title={title} description={description} type={type} createdAt={createdAt}/>
    </Box>
  </Paper>
}

export default FeedbackItem