import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ProfileAvatar = ({ overrideType }) => {
  const user = useSelector(({ user }) => user);
  const animatedVideo = user?.data?.reactions?.win;
  const avatar = user?.data?.image;
  const [type, setType] = useState(animatedVideo ? "VIDEO" : "IMAGE");

  const handleSwitchAvatarType = () => {
    setType((t) => (t === "VIDEO" ? "IMAGE" : "VIDEO"));
  };

  useEffect(() => {
    setType(overrideType);
  }, [overrideType]);

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      gap={2}
      position={"relative"}
    >
      <video
        key={animatedVideo?.slice(-5)}
        playsInline
        autoPlay
        muted
        loop
        width={"300px"}
        style={{
          borderRadius: "100%",
          position: "absolute",
          opacity: type === "VIDEO" ? 1 : 0,
        }}
      >
        {animatedVideo && <source src={animatedVideo} type="video/mp4" />}
        Your browser does not support the video tag.
      </video>
      <img
        src={avatar}
        width={"300px"}
        style={{ borderRadius: "100%", opacity: type === "IMAGE" ? 1 : 0 }}
      />
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleSwitchAvatarType}
        disabled={type === "IMAGE" ? !animatedVideo : false}
      >
        {type === "VIDEO" ? "Stop" : "Animate"}{" "}
      </Button>
    </Box>
  );
};

export default ProfileAvatar;
