import { configureStore } from "@reduxjs/toolkit";

import { gameReducer } from "../slices/gameSlice";
import { optionsReducer } from "../slices/optionsSlice";
import { roomReducer } from "../slices/roomSlice";
import { userReducer } from "../slices/userSlice";
import { connReducer } from "../slices/connectionSlice";
import { dialogReducer } from "../slices/dialogSlice";
import { snackbarReducer } from "../slices/snackbarSlice";
import { revisionReducer } from "../slices/revisionSlice";
import { tournamentReducer } from "../slices/tournamentSlice";

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["dialog/pushDialog"],
      },
    }),
  reducer: {
    tournament: tournamentReducer,
    room: roomReducer,
    user: userReducer,
    game: gameReducer,
    options: optionsReducer,
    connection: connReducer,
    dialog: dialogReducer,
    snackbar: snackbarReducer,
    revision: revisionReducer,
  },
});

export default store;
