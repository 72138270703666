import TutorialCarousel from "../../Tutorial";

const playTutorialItems = [
  { id: 1, src: "/assets/gif/choose.gif", title: "Choose", description: "There are no turns! Just tap two squares trying to match" },
  { id: 2, src: "/assets/gif/match.gif", title: "Match", description: "If the squares have a star ⭐️, that means the match is yours" },
  { id: 3, src: "/assets/gif/enemy.gif", title: "Enemy", description: "If the squares have a skull 💀, that means the match is from an enemy" },
  { id: 4, src: "/assets/gif/win.gif", title: "Win", description: "Remember, there are no turns, try to get matches as fast as posible to be the winner!" },
]

const avatarTutorialItems = [
  { id: 1, src: "/assets/gif/verify.gif", title: "Verify", description: "Click on verify profile and follow the steps to register your email" },
  { id: 2, src: "/assets/img/email.png", title: "Email", description: "You will receive an email like this, click the button to confirm" },
  { id: 3, src: "/assets/gif/profile.gif", title: "Profile", description: "Go to your profile to generate an avatar and stop being an egg" },
  { id: 4, src: "/assets/gif/gallery.gif", title: "Gallery", description: "You can see all the avatars you have generated in your profile" },
]
const coinsTutorialItems = [
  { id: 1, src: "/assets/gif/verification_coins.gif", title: "Verify", description: "You get 500 coins by verifying" },
  { id: 2, src: "/assets/gif/invite_coins.gif", title: "Invite", description: "You get coins for inviting people to play" },
  { id: 3, src: "/assets/gif/win.gif", title: "Win", description: "You get coins when you win a game" },
]

const config = {
  title: "Help",
  titleIcon: "support",
  titleIconImg: "/assets/img/life-buoy-6356803-5232047_small.png",
  variant: "expanded",
  items: [
    {
      label: "How do I play?",
      imgSrc: "joystick-7743968-6174582.png",
      description:
        "After creating a game for 2-4 players. Find the most matches to win!",
      component: <TutorialCarousel items={playTutorialItems}/>
    },
    {
      label: "How do I get my avatar?",
      imgSrc: "owl-7503933-6136919.png",
      description:
      "You need to be a verified user first in order to generate an avatar, this is only one of the benefits of being a verified user",
      component: <TutorialCarousel items={avatarTutorialItems}/>
    },
    {
      label: "How do I win coins?",
      imgSrc: "stack-of-coin-6673804-5526420.png",
      description:
      "You can win coins by playing games and winning, or by inviting friends to play",
      component: <TutorialCarousel items={coinsTutorialItems}/>
    },
  ],
};

export default config;
