import { Box, Button, SwipeableDrawer, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";

const SocketMessages = () => {
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isAdmin = useSelector(({ user }) => user?.isAdmin);
  const { messages, connected } = useSelector(
    ({ connection }) => connection ?? {}
  );

  const handleOpen = () => {
    if (!open) {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  return isAdmin ? (
    <>
      <Box
        position={"fixed"}
        display={"flex"}
        justifyContent={"flex-end"}
        bottom={"27%"}
        left={0}
        sx={{ transform: "translateX(calc(0% - 25px)) rotate(-90deg)" }}
      >
        <Button
          variant="contained"
          color="inherit"
          style={{ filter: "grayscale(100%)" }}
          onClick={handleOpen}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Typography> Debug </Typography>
          </Box>
        </Button>
      </Box>
      <SwipeableDrawer
        anchor="left"
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        PaperProps={{sx: isMobile ? {}: {width: "90%"}}}
      >
        { open && <Box display={"flex"} p={3} flexDirection={"column"} gap={2} sx={{backgroundColor: "#222", height: '100vh', scroll: 'auto'}}>
          <Typography variant={'h5'}> Socket worker log | connected: {JSON.stringify(connected)} </Typography>
          {messages.map((message, i) => (
            <Typography variant={'subtitle2'} fontFamily={'monospace'} key={`${message.slice(0, 10)}${i}`} color={message?.startsWith("SENDING") ? 'primary' : 'secondary'}>
              {message}
            </Typography>
          ))}
        </Box>}
      </SwipeableDrawer>
    </>
  ) : null;
};

export default SocketMessages;

