import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import CustomSelect from "../custom/CustomSelect/CustomSelect";

const getOptionLabel = (value, options = []) =>
  options.find((option) => option.value === value)?.label;

const FormSelect = ({
  control,
  name,
  label,
  options,
  viewMode = false,
  variant,
  sx,
  labelSx,
  labelVariant,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) =>
        viewMode ? (
          <>
            <Typography variant="caption"> {label}</Typography>
            <Typography variant="h6">
              {getOptionLabel(field.value, options)}
            </Typography>
          </>
        ) : (
          <FormControl>
            <InputLabel sx={labelSx} variant={labelVariant}>
              {label}
            </InputLabel>
            <CustomSelect
              variant={variant}
              label={label}
              value={field.value}
              onChange={(ev) => {
                field.onChange(ev.target.value);
              }}
              sx={sx}
            >
              {options?.map((option) => (
                <MenuItem
                  key={option?.value}
                  value={option?.value}
                  disabled={option?.disabled}
                >
                  {option?.label}
                </MenuItem>
              ))}
            </CustomSelect>
          </FormControl>
        )
      }
    />
  );
};

export default FormSelect;
