import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container, LinearProgress, Typography } from "@mui/material";
import { checkTokenExists, verifyToken } from "../../services/profileServices";
import { popDialog, pushDialog } from "../../slices/dialogSlice";
import { updateUser } from "../../slices/userSlice";
import { useWorker } from "../WorkerContext";

const VerificationSocial = ({ userEmail, token, returningUser = false }) => {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const socketWorker = useWorker();

  const verifyEmailExists = async () => {
    try {
      // TODO: we're using checkTokenExists to add the username to
      // TODO: redirect the user to the game but it is redundant
      // TODO: Reduce the code to just use verifyToken
      const { username } = await checkTokenExists({ token });
      const { isVerified } = await verifyToken({ token });
      if (username) {
        if (!user.name) {
          dispatch(updateUser({ name: username }));
          localStorage.setItem("user", JSON.stringify({ userName: username }));
        }
        dispatch(popDialog());
        socketWorker?.postMessage({
          type: "EMAIL_VERIFY",
          payload: { success: isVerified },
        });
        dispatch(
          pushDialog({
            id: "VERIFICATION_SUCCESFUL",
            props: {
              userEmail,
              returningUser,
            },
          })
        );
      }
    } catch (err) {
      dispatch(popDialog());
      dispatch(
        pushDialog({
          id: "VERIFICATION_UNSUCCESFUL",
          props: {
            userEmail,
            returningUser,
          },
        })
      );
    }
  };

  useEffect(() => {
    verifyEmailExists();
  }, []);

  return (
    <Container maxWidth="sm" style={{ position: "relative" }}>
      <Box
        p={5}
        display="flex"
        flexDirection={"column"}
        gap={"1rem"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Typography variant={"h6"} align="center">
          Processing Verification
        </Typography>
        <Box position={"absolute"} bottom={0} width={"100%"}>
          <LinearProgress color="inherit" style={{ height: "5px" }} />
        </Box>
      </Box>
    </Container>
  );
};

export default VerificationSocial;
