import _ from "lodash";
import { Box, Button, Icon, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SimpleCard from "./SimpleCard";

const AvailableTournamentsCard = ({ availableTournaments }) => {
  const navigate = useNavigate();

  const goToCreateTournament = () => {
    navigate("/tournament/create", { replace: true });
  };

  return (
    <SimpleCard
      action={() => {
        if (availableTournaments) {
          navigate("/tournament", { replace: "true" });
        }
      }}
    >
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Typography align="center" variant="h6">
          Tournies
        </Typography>
          <Icon fontSize="medium">emoji_events</Icon>
        <Typography align="center" variant="h2">
          {availableTournaments || (
            <Button
              variant="bigfoot"
              sx={{ fontSize: "1rem" }}
              onClick={goToCreateTournament}
            >
              START
            </Button>
          )}
        </Typography>
        {availableTournaments ? (
          <Typography variant="caption"> Available</Typography>
        ) : null}
      </Box>
    </SimpleCard>
  );
};
const AvailableMatchesCard = ({ availableRooms }) => {
  const navigate = useNavigate();

  const goToCreateMatch = () => {
    navigate("/room/create", { replace: true });
  };

  return (
    <SimpleCard
      action={() => {
        if (availableRooms) {
          navigate("/room", { replace: "true" });
        }
      }}
    >
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Typography align="center" variant="h6">
          Matches
        </Typography>
          <Icon fontSize="medium">play_arrow</Icon>
        <Typography align="center" variant="h2">
          {availableRooms || (
            <Button
              variant="bigfoot"
              sx={{ fontSize: "1rem" }}
              onClick={goToCreateMatch}
            >
              START
            </Button>
          )}
        </Typography>
        {availableRooms ? (
          <Typography variant="caption"> Available</Typography>
        ) : null}
      </Box>
    </SimpleCard>
  );
};

const GameSection = ({ tournaments, rooms }) => {
  const availableTournaments = _.size(tournaments);
  const availableRooms = _.size(
    Object.values(rooms).filter((room) => !room.tournamentRoom)
  );
  return (
    <Paper
      sx={{
        bgcolor: "rgba(32, 103, 170,0.1)",
        height: "90%",
        padding: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={1}
        height={"100%"}
      >
        <Typography variant="h4">Play</Typography>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={2}
          height={"100%"}
          width={"100%"}
          mb={2}
        >
          <AvailableTournamentsCard
            availableTournaments={availableTournaments}
          />
          <AvailableMatchesCard availableRooms={availableRooms} />
        </Box>
      </Box>
    </Paper>
  );
};

export default GameSection;
