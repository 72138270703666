import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const accessObjectKey = (object, keyAccessor) => {
  return keyAccessor.split(".").reduce((value, key) => value?.[key], object);
};

const useMappedState = (selector, mapper) => {
  const [mappedState, setMappedState] = useState({});
  const listeningState = useSelector(selector);

  useEffect(() => {
    Object.keys(mapper ?? {}).forEach((fieldKey) => {
      const accessor = mapper[fieldKey];
      const value =
        typeof accessor === "function"
          ? accessor(listeningState)
          : accessObjectKey(listeningState, accessor);
      setMappedState((s) => ({ ...s, [fieldKey]: value }));
    });
  }, [listeningState, mapper]);

  return mappedState;
};

export default useMappedState;
