import { Box, Typography } from "@mui/material";
import { useState } from "react";

const MatchHeader = ({ headerTitle, matchName, titleBuilder, GearComponent }) => {
  const [randomMatch] = useState(titleBuilder(matchName));

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Typography align="center" variant="h4" color={"grey.200"}>
        {headerTitle ?? randomMatch}
      </Typography>
      {GearComponent}
    </Box>
  );
};

export default MatchHeader