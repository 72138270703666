import { createSlice } from "@reduxjs/toolkit";

export const optionsInitialState = {
  debugMode: false,  
  deathmatchFilter: false,  
};

const optionsSlice = createSlice({
  name: "options",
  initialState: optionsInitialState,
  reducers: {
    toggleDebug: (state) => {
      const currentDebugMode = state.debugMode;
      state.debugMode = !currentDebugMode;
    },
    setDeathmatchFilter: (state, action) => {      
      state.deathmatchFilter = action.payload;
    },
  },
});

export const optionsReducer = optionsSlice.reducer;
export const { toggleDebug, setDeathmatchFilter } = optionsSlice.actions;
export default optionsSlice;
