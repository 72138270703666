import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


import { startGame } from "../slices/gameSlice";
import { useWorker } from "./WorkerContext";

const Countdown = ({ action, startingAt = 3 }) => {
  const socketWorker = useWorker();

  const [count, setCount] = useState(startingAt);
  const started = useSelector(({ game }) => game.started);
  const roomName = useSelector(({ room }) => room.id);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (started) {
      navigate(`/${roomName}/game`, { replace: true });
    }
    //eslint-disable-next-line
  }, [started]);

  useEffect(() => {
    const tId = setTimeout(() => {
      const newCount = count - 1;
      if (newCount <= 0) {
        dispatch(startGame({ roomName }));
        socketWorker.postMessage({
          type: "GAME_STARTED",
          payload: { roomName },
        });

        action();
      } else {
        setCount(newCount);
      }
    }, 1000);

    return () => clearTimeout(tId);
    //eslint-disable-next-line
  }, [count]);

  return count;
};

export default Countdown;
