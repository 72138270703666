// const exampleResponse = {
//   "flagged": true,
//   "categories": {
//     "sexual": false,
//     "hate": false,
//     "harassment": true,
//     "self-harm": false,
//     "sexual/minors": false,
//     "hate/threatening": false,
//     "violence/graphic": false,
//     "self-harm/intent": false,
//     "self-harm/instructions": false,
//     "harassment/threatening": false,
//     "violence": true
//   },
//   "category_scores": {
//     "sexual": 0.0000011269439,
//     "hate": 0.0000059973545,
//     "harassment": 0.41784927,
//     "self-harm": 4.2250144e-7,
//     "sexual/minors": 3.4914123e-9,
//     "hate/threatening": 8.936201e-7,
//     "violence/graphic": 9.4713215e-7,
//     "self-harm/intent": 6.553685e-8,
//     "self-harm/instructions": 1.04184314e-10,
//     "harassment/threatening": 0.3466189,
//     "violence": 0.9951959
//   }
// }

export const categoryAdjectives = {
  "sexual": "sexual",
  "hate": "hateful",
  "harassment": "harrasing",
  "self-harm": "violent",
  "sexual/minors": "sexual",
  "hate/threatening": "hateful",
  "violence/graphic": "violent",
  "self-harm/intent": "violent",
  "self-harm/instructions": "violent",
  "harassment/threatening": "harrasing",
  "violence": "violent",

}
