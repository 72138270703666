import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { resetUser } from "../slices/userSlice";
import { deleteUser } from "../services/userServices";
import { popDialog } from "../slices/dialogSlice";

const DeleteAccount = (props) => {
  const user = useSelector(({ user }) => user);

  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const redirectToLogin = () => {
    dispatch(resetUser());
    navigate("/", { replace: true });
  };

  async function handleDeletion() {
    await deleteUser(user?.userId);
    redirectToLogin();
    dispatch(popDialog());
  }

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        // height: "95vh",
        justifyContent: "center",
        padding: "10px 20px",
      }}
      maxWidth={"sm"}
    >
      <Box
        px={matchesSM ? 3 : 5}
        display="flex"
        flexDirection={"column"}
        gap={"1rem"}
      >
        <Typography
          pt={3}
          pb={1}
          color={"error"}
          variant={"h5"}
          textAlign={"center"}
        >
          You are about to delete your account, this step is irreversible, your
          account will be deleted and you will be logged out.
        </Typography>
        <Button onClick={handleDeletion} variant="outlined" color="error">
          DELETE ACCOUNT
        </Button>
      </Box>
    </Container>
  );
};

export default DeleteAccount;
