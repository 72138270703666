import { createSlice } from "@reduxjs/toolkit";

export const tournamentInitialState = {
  id: "",
  users: {},
  status: "",
  options: {
    maxUsers: null,
    maxCardPairs: null,
    enemyReveal: null,
  },
  winner: null,
  levels: [],
};

const tournamentSlice = createSlice({
  name: "tournament",
  initialState: tournamentInitialState,
  reducers: {
    updateTournament: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetTournament: () => {
      return tournamentInitialState;
    },
  },
});

export const tournamentReducer = tournamentSlice.reducer;
export const { updateTournament, resetTournament } = tournamentSlice.actions;
export default tournamentSlice;
