export const findCurrentLevel = (levels, userName) => {
  const { levelIndex } = levels.reduce(
    (currentLevel, level) => {
      if (currentLevel.found) return currentLevel;
      const levelBracket = level.find(
        (bracket) => !bracket.finished && bracket?.users?.[userName]
      );
      if (levelBracket) {
        currentLevel.found = true;
      } else {
        currentLevel.levelIndex++;
      }
      return currentLevel;
    },
    { levelIndex: 0, found: false }
  );
  return levelIndex;
};

export const getLevelLabel = (currentLevel, maxLevel) =>
  currentLevel === maxLevel
    ? "Finals"
    : currentLevel === maxLevel - 1
    ? "Semi finals"
    : currentLevel === maxLevel - 2
    ? "Quarter finals"
    : currentLevel === maxLevel - 3
    ? "Round of 16"
    : `Level ${currentLevel}`;
