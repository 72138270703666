import { Button } from "@mui/material";
import { Controller } from "react-hook-form";

const FormButton = ({
  control,
  name,
  label,
  sx,
  actionType,
  variant,
  onClick,
  onSubmit,
}) => {
  const handleClick = () => {
    if (actionType?.toUpperCase() === "SUBMIT") {
      onSubmit?.();
    } else if (actionType?.toUpperCase() === "CUSTOM") {
      onClick?.();
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Button variant={variant} onClick={handleClick} sx={sx}>
          {label}
        </Button>
      )}
    />
  );
};

export default FormButton;
