import { Box, Button, Container, Link, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../services/profileServices";
import { useWorker, useWorkerListener } from "../../components/WorkerContext";
import { getGallery } from "../../services/avatarServices";
import SimpleCard from "./SimpleCard";
import UsernameCard from "./UsernameCard";
import GameSection from "./GameSection";
import UserSection from "./UserSection";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { pushDialog } from "../../slices/dialogSlice";

const requirementLabels = {
  GAMES: "play three games",
  VERIFY: "verify your account",
};

const Verify = ({ label }) => {
  const dispatch = useDispatch();
  const openVerification = () => {
    dispatch(
      pushDialog({
        id: "VERIFICATION_BENEFITS",
      })
    );
  };
  return (
    <Link sx={{ cursor: "pointer" }} onClick={openVerification}>
      {" "}
      {label}
    </Link>
  );
};

const requirementComponents = {
  GAMES: ({ label }) => <> {label}</>,
  VERIFY: ({ label }) => <Verify label={label} />,
};

const getLabelComponent = (requirements, requirementObject) => {
  const texts = requirements.reduce((texts, requirement, index) => {
    const isLast = index === requirements?.length - 1;
    const meetsRequirement = requirementObject[requirement];
    const requirementLabel = texts.length
      ? `, ${isLast ? "and " : ""}${requirementLabels?.[requirement]}`
      : _.capitalize(requirementLabels?.[requirement]);
    const RequirementComponent = requirementComponents?.[requirement];

    if (!meetsRequirement)
      texts.push(<RequirementComponent label={requirementLabel} />);

    return texts;
  }, []);

  return <>{texts.map((e) => e)} </>;
};

const FeedbackCard = ({ stats }) => {
  const navigate = useNavigate();
  const verified = useSelector(({ user }) => user.verified);
  const feedbackEnabled = useSelector(
    ({ user }) => !!user?.data?.options?.feedbackEnabled
  );
  const requirements = ["VERIFY", "GAMES"];
  const requirementObject = {
    GAMES: stats?.played_games >= 3,
    VERIFY: verified,
  };

  return (
    <SimpleCard>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Typography align="center" variant="h6">
          Feedback
        </Typography>
        {!feedbackEnabled ? (
          <Typography sx={{ color: "white" }} align="center" variant="caption">
            {getLabelComponent(requirements, requirementObject)}
          </Typography>
        ) : (
          <Button
            variant="outlined"
            onClick={() => {
              navigate("/feedback", { replace: true });
            }}
          >
            Go to Feedback
          </Button>
        )}
      </Box>
    </SimpleCard>
  );
};

const PlayedGamesCard = ({ stats }) => {
  return (
    <SimpleCard
    >
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Typography align="center" variant="h6">
          Games
        </Typography>
        <Typography align="center" variant="caption">
          played
        </Typography>
        <Typography align="center" variant="h5">
          {stats?.played_games || 0}
        </Typography>
      </Box>
    </SimpleCard>
  );
};

const useUserInfo = (userName) => {
  const [profile, setProfile] = useState({});
  const [stats, setStats] = useState({});

  useEffect(() => {
    if (!profile || profile?.username !== userName) {
      (async () => {
        const { profile, stats } = await getProfile(userName);
        setProfile(profile);
        setStats(stats);
      })();
    }
  }, [userName, profile, setStats]);
  return { stats, profile };
};

const HomePage = () => {
  const socketWorker = useWorker();
  const userName = useSelector(({ user }) => user.name);
  const { stats, profile } = useUserInfo(userName);

  const [tournaments, setTournaments] = useState({});
  const [rooms, setRooms] = useState({});
  const [images, setImages] = useState([]);

  const handleWorkerMessage = (event) => {
    if (event.data.type === "TOURNAMENTS_LIST") {
      const tournaments = event.data.payload;
      setTournaments(tournaments);
    } else if (event.data.type === "ROOMS_LIST") {
      const rooms = event.data.payload;
      setRooms(rooms);
    }
  };

  useWorkerListener(handleWorkerMessage);

  useEffect(() => {
    socketWorker.postMessage({ type: "ROOMS_GET" });
    socketWorker.postMessage({ type: "TOURNAMENTS_GET" });
    (async () => {
      const { images } = await getGallery({ name: userName });
      setImages(images);
    })();
  }, [socketWorker]);

  return (
    <Container maxWidth="lg" sx={{ mx: "auto", my: 3 }}>
      <Box display={"flex"} flexDirection={"column"} gap={2} height={"75vh"}>
        <UsernameCard userName={userName} />

        <Paper
          sx={{
            bgcolor: "rgba(0,0,0,0.3)",
            padding: 1,
          }}
        >
          <Box display={"flex"} gap={2} height={"55vh"}>
            <Box width={"60%"}>
              <UserSection
                userName={userName}
                profile={profile}
                images={images}
              />
            </Box>
            <Box width={"40%"}>
              <GameSection tournaments={tournaments} rooms={rooms} />
            </Box>
          </Box>
        </Paper>
        <Box display={"flex"} gap={2} height={"10vh"}>
          <Box width={"60%"}>
            <FeedbackCard stats={stats} />
          </Box>
          <Box width={"40%"}>
            <PlayedGamesCard stats={stats} />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default HomePage;
