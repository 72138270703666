import { Box, Button, DialogActions, Typography } from "@mui/material";
import { formatName, isBase64 } from "../utils/stringUtils";
import { useDispatch, useSelector } from "react-redux";
import useScreenSize from "../hooks/useScreenSize";
import { useWorker } from "./WorkerContext";
import { resetRoom } from "../slices/roomSlice";
import { popDialog, pushDialog } from "../slices/dialogSlice";
import { useCallback, useEffect } from "react";
import _ from "lodash";
import Countdown from "./Countdown";
import { useNavigate } from "react-router-dom";
import { rewardInviterPlayer } from "../services/profileServices";

const PlayerName = ({ player }) => {
  return player
    ? [...formatName(player?.name)?.split(" ")]?.map((name) => (
        <PlayerNamePart name={name} />
      ))
    : null;
};
const PlayerNamePart = ({ name }) => (
  <Typography
    textAlign={"center"}
    sx={{
      fontSize: { xs: "0.7rem", sm: "1rem" },
      display: "flex",
      flexDirection: "column",
    }}
  >
    {name}
  </Typography>
);

const useRedirectToGame = () => {
  const started = useSelector(({ game }) => game.started);
  const roomName = useSelector(({ room }) => room.id);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (started) {
      dispatch(popDialog());
      navigate(`/${roomName}/game`, { replace: true });
    }
    //eslint-disable-next-line
  }, [started]);
};

const useRewardInvitation = () => {
  const socketWorker = useWorker();
  const allUsers = useSelector(({ room }) => room.users);
  const usersInRoom = _.size(allUsers);

  useEffect(() => {
    const invitationInfo = localStorage.getItem("invitationInfo");
    if (invitationInfo) {
      (async () => {
        try {
          const { newInvitee, inviteCode } = JSON.parse(invitationInfo);
          const { inviter } = await rewardInviterPlayer({
            newInvitee,
            inviteCode,
          });
          // TODO: Create got:rewards socket message, maybe an assortment of specific messages
          // TODO: To trigger behavior correspending to different rewards
          socketWorker.postMessage({
            type: "TRIGGER_REVISION",
            payload: { user: inviter },
          });
          localStorage.removeItem("invitationInfo");
        } catch (err) {
          console.error(err);
        }
      })();
    }
    // eslint-disable-next-line
  }, [usersInRoom, socketWorker]);
};

const RoomPlayersReady = () => {
  const deathmatch = useSelector(({ room }) => room?.options?.deathmatch);
  const allUsers = useSelector(({ room }) => room.users);
  const usersInRoom = _.size(allUsers);
  const maxUsers = useSelector(({ room }) => room?.options?.maxUsers);
  const roomName = useSelector(({ room }) => room.id);
  const fromTournament = useSelector(({ room }) => room.tournamentRoom);
  const userName = useSelector(({ user }) => user.name);
  const playerList = Object.values(allUsers ?? {});
  const playersCount = playerList?.length;
  const allReady = playerList.every((user) => user.ready);
  const userReady = !!allUsers?.[userName]?.ready;
  const screenSize = useScreenSize();
  let singleRow = screenSize?.width / screenSize?.height > 0.65;

  const socketWorker = useWorker();
  const dispatch = useDispatch();
  useRedirectToGame();
  useRewardInvitation();

  if (playersCount <= 2) {
    singleRow = true;
  }
  const playerRows = singleRow
    ? { result: [playerList] }
    : playerList.reduce(
        (acc, curr, i) => {
          if (!(i % 2)) {
            acc.result[acc.counter] = [curr];
          } else {
            acc.result[acc.counter].push(curr);
            acc.counter++;
          }
          return acc;
        },
        { counter: 0, result: [] }
      );

  const leaveRoom = useCallback(() => {
    socketWorker.postMessage({
      type: "ROOM_LEAVE",
      payload: { roomName, userName },
    });
    dispatch(resetRoom());
  }, [dispatch, roomName, socketWorker, userName]);

  const titleBuilder = (roomName) => `Room ${roomName}`;

  const openPlayersReadyDialog = useCallback(() => {
    dispatch(popDialog());
    dispatch(
      pushDialog({
        id: "ROOM_PLAYERS_READY",
        dialogProps: {
          fullWidth: true,
        },
        options: { disableBackdropClick: true, disableEscapeKey: true },
      })
    );
  }, [dispatch]);

  const handleSwitchToWaiting = useCallback(() => {
    dispatch(popDialog());
    dispatch(
      pushDialog({
        id: "MATCH_WAIT_LIST",
        props: {
          matchName: roomName,
          titleBuilder,
          onExit: leaveRoom,
          fromTournament,
          onPlayersReady: openPlayersReadyDialog,
          selector: ({ room }) => room,
          mapper: {
            users: "users",
            maxUsers: "options.maxUsers",
            readyUsers: (room) =>
              Object.values(room?.users ?? {}).filter((user) => user.ready),
            waitingUsers: (room) => Object.values(room?.users ?? {}),
          },
        },
        dialogProps: {
          fullWidth: true,
        },
        options: { disableBackdropClick: true, disableEscapeKey: true },
      })
    );
  }, [dispatch, fromTournament, leaveRoom, openPlayersReadyDialog, roomName]);

  const handleReady = () => {
    socketWorker.postMessage({
      type: "ROOM_READY",
      payload: { roomName, userName },
    });
  };

  const handleExitRoom = () => {
    leaveRoom();
    dispatch(popDialog());
  };

  useEffect(() => {
    if (usersInRoom && usersInRoom < maxUsers) {
      handleSwitchToWaiting();
    }
  }, [handleSwitchToWaiting, leaveRoom, maxUsers, usersInRoom]);

  useEffect(() => {
    if (deathmatch) {
      socketWorker.postMessage({
        type: "ROOM_READY",
        payload: { roomName, userName },
      });
    }
  }, [deathmatch, roomName, socketWorker, userName]);

  return (
    <>
      {playerRows?.result?.map((playerRow, index) => (
        <Box m={0} p={0}>
          <Box display="flex">
            {playerRow.map((player) => (
              <Box
                sx={{ width: "50%" }}
                style={{ filter: player.ready ? "none" : "grayscale(100%)" }}
                p={0}
              >
                {!index && !singleRow ? <PlayerName player={player} /> : null}
                <img
                  alt={player?.name}
                  style={{ width: "100%" }}
                  src={
                    isBase64(allUsers[player.name]?.avatar)
                      ? `data:image/png;base64,${allUsers[player.name]?.avatar}`
                      : allUsers[player.name]?.avatar
                  }
                />
                {index || singleRow ? <PlayerName player={player} /> : null}
              </Box>
            ))}
          </Box>
        </Box>
      ))}
      {!deathmatch && (
        <>
          <Typography p={1} align="center">
            Are you ready?
          </Typography>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={handleExitRoom}
              fullWidth
              variant="contained"
              disabled={allReady}
              color="secondary"
            >
              LEAVE
            </Button>
            <Button
              onClick={handleReady}
              fullWidth
              variant="contained"
              disabled={userReady}
            >
              {"READY"}
            </Button>
          </DialogActions>
        </>
      )}
      {allReady && (
        <Box
          position={"fixed"}
          top={0}
          left={0}
          width="100vw"
          height="100vh"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            textAlign={"center"}
            variant="h1"
            style={{ fontSize: "max(40vw, 40vh)" }}
            sx={{
              fontWeight: 600,
              textShadow:
                "10px 0 #000, -10px 0 #000, 0 10px #000, 0 -10px #000, 6px 6px #000, -6px -6px #000, 6px -6px #000, -6px 6px #000",
            }}
          >
            <Countdown />
          </Typography>
        </Box>
      )}
    </>
  );
};

export default RoomPlayersReady;
