import { createRequest } from "../utils/requestUtils";

export const getFeedbackList = () => {
	return createRequest()
		.get('feedbacks')
		.then((res) => res.data)
}

export const createFeedback = (feedbackData) => {
	return createRequest()
		.post('feedbacks', feedbackData)
		.then((res) => res.data)
}

export const upvoteFeedback = (feedbackId, profileId) => {
	return createRequest()
		.post(`feedbacks/${feedbackId}/upvote`, { profileId })
		.then((res) => res.data)
}
export const downvoteFeedback = (feedbackId, profileId) => {
	return createRequest()
		.post(`feedbacks/${feedbackId}/downvote`, { profileId })
		.then((res) => res.data)
}
export const evaluateFeedback = (feedback) => {
	return createRequest()
		.post(`feedbacks/evaluate`, { feedback })
		.then((res) => res.data)
}
