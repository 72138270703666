const powerUpImages = {
  bomb: "bomb-6823670-5602871.png",
  shock: "electricity-5282551-4413092.png",
  freeze: "freeze-4409672-3718925.png",
  spin: "face-with-spiral-eyes-9435820-7705072.png",
  empty: "empty_64px.png",
};

export const powerUpStatusImageURLs = {
  bombExploded: "/assets/img/powerups/bomb-6823670-5602871.png",
  shocked: "/assets/img/powerups/electricity-5282551-4413092.png",
  freezed: "/assets/img/powerups/freeze-4409672-3718925.png",
  spinned: "/assets/img/powerups/face-with-spiral-eyes-9435820-7705072.png",
};

export const statusAnimationDurations = {
  bombExploded: 1000,
  shocked: 6000,
  freezed: 3000,
  spinned: 5000,
};

export const PowerUpImage = ({ powerUpName, alt, imgProps = {} }) => (
  <img
    src={`/assets/img/powerups/${powerUpImages?.[powerUpName]}`}
    alt={alt}
    width={75}
    {...imgProps}
  />
);
