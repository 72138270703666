import { useDispatch } from "react-redux";
import { useWorkerListener } from "../components/WorkerContext";
import { updateRoom } from "../slices/roomSlice";
import { updateGame } from "../slices/gameSlice";
import { updateTournament } from "../slices/tournamentSlice";

const GlobalListener = () => {
  const dispatch = useDispatch();

  useWorkerListener((ev) => {
    if (ev.data.type === "ROOM_UPDATE") {
      const room = ev.data.payload;
      dispatch(updateRoom(room));
    } else if (ev.data.type === "GAME_START") {
      const game = ev.data.payload;
      dispatch(updateGame(game));
    } else if (ev.data.type === "TOURNAMENT_UPDATE") {
      const tournament = ev.data.payload;
      dispatch(updateTournament(tournament));
    }
  });

  return null;
};

export default GlobalListener;
