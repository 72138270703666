import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const useRedirectAction = (callback) => {
  const location = useLocation()
  const prevUrl = useRef()
  const currentUrl = useRef(location.pathname)

  useEffect(() => {
    prevUrl.current = currentUrl.current
    currentUrl.current = location.pathname

    callback?.(prevUrl.current, currentUrl.current)
  }, [location])

};
export default useRedirectAction;
