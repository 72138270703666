import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

const noVideoPaths = ["/verify"]

const IntroVideo = ({onEnd}) => {
  const isHorizontal = window.innerWidth > window.innerHeight // TODO: check alternatives

  const videoRef = useRef(null);
  const [currentVideo, setCurrentVideo] = useState(0);

  const videos = [
    `/assets/video/common_zen_splash_${isHorizontal ? "horizontal" : "vertical"}.mp4`,
    `/assets/video/p3co_splash_${isHorizontal ? "horizontal" : "vertical"}.mp4`,
    `/assets/video/intro_new_${isHorizontal ? "horizontal" : "vertical"}.mp4`
  ];

  const handleVideoEnd = () => {
    if (currentVideo + 1 < videos.length) {
      setCurrentVideo(currentVideo + 1);
    }
    if (currentVideo === (videos.length - 1 )) {
      onEnd()
    }
  };

  useEffect(() => {
    if (videoRef.current && currentVideo > 0) {
      videoRef.current?.load();  // Load the new video source
      videoRef.current?.play();  // Play the next video
    }
  }, [currentVideo]);
  return (
    <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden' }} onClick={handleVideoEnd}>
      <video width="100%" height="100%" ref={videoRef} autoPlay muted onEnded={handleVideoEnd} style={{objectFit: "cover"}} playsInline>
        <source src={videos[currentVideo]} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

const IntroVideoWrapper = ({children}) => {
  const [showSplash, setShowSplash] = useState(false); // Default to false
  const location = useLocation()
  const isLocationBlacklisted = noVideoPaths.includes(location?.pathname)

  useEffect(() => {
    // Check if the user has seen the splash screen before
    const hasSeenSplash = localStorage.getItem('hasSeenSplash');

    if (!hasSeenSplash && !isLocationBlacklisted) {
      setShowSplash(true);
    }

    
  }, [isLocationBlacklisted]);
  
  const handleVideoEnd = () => {
    // Store in localStorage that the user has seen the splash screen
    localStorage.setItem('hasSeenSplash', 'true');
    setShowSplash(false);
  };

  return showSplash ? <IntroVideo onEnd={handleVideoEnd}/> : <>{children}</>
}

export default IntroVideoWrapper