import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Typography,
  Button,
  Avatar,
  Chip,
  Paper,
  useMediaQuery,
} from "@mui/material";

import { popDialog, pushDialog } from "../../slices/dialogSlice";
import { incrementDataRevision } from "../../slices/revisionSlice";
import Legend from "../legend/Legend";
import LegendList from "../legend/LegendList";
import subscriptionConfig from "../legend/configs/subscriptionConfig";

import ReactConfetti from "react-confetti";
import { useTheme } from "@emotion/react";

const SizedConfetti = React.forwardRef((passedProps, ref) => {
  return (
    <ReactConfetti
      width={"600px"}
      height={"700px"}
      {...passedProps}
      ref={ref}
      numberOfPieces={200}
    />
  );
});

const drawStar = (ctx) => {
  ctx.font = "20px serif";
  ctx.fillText("⭐️", -10, 10);
};

const VerificationBenefits = ({ avatar }) => {
  const userEgg = useSelector(({ user }) => user?.data?.image);
  const username = useSelector(({ user }) => user?.name);
  const dispatch = useDispatch();

  const [translateConfetti, setTranslateConfetti] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTranslateConfetti(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const onVerifyClick = () => {
    dispatch(incrementDataRevision({ event: "userRevision" }));

    dispatch(popDialog());
    dispatch(pushDialog({ id: "VERIFICATION_SEND_EMAIL" }));
  };

  const handleClose = () => {
    dispatch(popDialog());
  };

  const renderItem = (item) => (
    <Legend
      key={item.label}
      svg={item.svg}
      label={item.label}
      imgSrc={item?.imgSrc}
      icon={item?.icon}
      description={item?.description}
    >
      {item?.items?.map(renderItem)}
    </Legend>
  );

  return (
    <Box p={2} style={{position: 'relative', overflow: !translateConfetti ? 'hidden' : 'auto'}}>
      <SizedConfetti
        style={
          translateConfetti
            ? {
                transform: "translateY(-1200px)",
                transition: "transform 1.5s ease-in",
              }
            : {}
        }
        drawShape={drawStar}
        run={!translateConfetti}
      />
      <Box display="flex" justifyContent="center" columnGap={1}>
        <Typography
          variant={isMobile ? "h5" : "h4"}
          component="h4"
          align="center"
          sx={{ mb: 2 }}
        >
          Unverified egg
        </Typography>
        <Avatar
          alt={username?.toUpperCase()}
          style={{ borderRadius: "100%" }}
          src={userEgg}
        />
      </Box>
      <Paper sx={{ mb: 2 }}>
        <Box
          p={2}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box>
            <Chip label={"Free"} sx={{ mb: 1 }} size="small" />
            <Typography>
              {!isMobile && (
                <>
                  <b>Get a star and stop being an egg! </b>
                  <br />
                </>
              )}
              {"Get 500 coins, AI generated avatars, and more!"}
            </Typography>
          </Box>
          <Typography variant="h5">⭐️</Typography>
        </Box>
      </Paper>
      {!isMobile && (
        <Typography variant="h6" align="center" sx={{ mb: 2 }}>
          Includes
        </Typography>
      )}
      <LegendList>{subscriptionConfig?.items?.map(renderItem)}</LegendList>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={onVerifyClick}
        sx={{ mt: 3 }}
      >
        Verify Now
      </Button>
      <Button
        fullWidth
        variant="text"
        color="secondary"
        onClick={handleClose}
        sx={{ mt: 1, fontSize: "1.5rem" }}
      >
        Not Yet
      </Button>
    </Box>
  );
};

export default memo(VerificationBenefits);
