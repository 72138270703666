import { useNavigate } from "react-router-dom";
import SimpleCard from "./SimpleCard";
import { Box, Typography } from "@mui/material";

const UsernameCard = ({ userName }) => {
  const navigate = useNavigate();
  return (
    <SimpleCard
      action={() => {
        navigate("/profile", { replace: true });
      }}
    >
      <Box width={"100%"}>
        <Typography align="center" variant="h2">
          {userName}
        </Typography>
      </Box>
    </SimpleCard>
  );
};

export default UsernameCard
