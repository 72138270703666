import { Typography } from "@mui/material";

function Logo() {
  return (
    <div style={{ display: "flex", position: "relative" }}>
      <Typography
        type={"h1"}
        className="logo-font gradient-text-border"
        textAlign={"center"}
        style={{ fontFamily: "rocket", position: "absolute" }}
      >
        <span>Project</span>
        Memory War
      </Typography>
      <Typography
        type={"h1"}
        style={{ fontFamily: "rocket" }}
        className="logo-font gradient-text background-clip"
        textAlign={"center"}
      >
        <span>Project</span>
        Memory War
      </Typography>
    </div>
  );
}

export default Logo;
