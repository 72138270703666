import {
  forwardRef, useImperativeHandle, useState
} from "react";

// third party
// import Draggable from "react-draggable";

// styles

// mui
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

// icons
import Close from "@mui/icons-material/Close";

// components
import LegendList from "./LegendList";
import Legend from "./Legend";
import { ExpandMore } from "@mui/icons-material";
import { Icon, useMediaQuery, useTheme } from "@mui/material";
import { generateLegendImageComponent } from "./utils/legend-utils";

const LegendImage = generateLegendImageComponent("subscription");

const LegendDialog = ({ config, children, onClose }, ref) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [expandedChild, setDialogExpandedChild] = useState(null);
  const showExpandedChild = config.variant === "expanded" && expandedChild;
  // const classes = useStyles();

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
    close: () => {
      setOpen(false);
    },
    toggle: () => {
      setOpen((open) => !open);
    },
  }));

  const handleClose = (ev, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
      onClose && onClose();
    }
  };

  const handleExpand = () => {
    setDialogExpandedChild(null);
  };

  const renderItem = (item) => (
    <Legend
      key={item.label}
      label={item.label}
      imgSrc={item?.imgSrc}
      icon={item?.icon}
      description={item?.description}
      component={item?.component}
      setDialogExpandedChild={setDialogExpandedChild}
    >
      {item?.items?.map(renderItem)}
    </Legend>
  );

  return (
    open && (
      <Dialog
        open={open}
        onClose={handleClose}
        disableAutoFocus
        hideBackdrop
        disableScrollLock
        PaperProps={{
          sx: {
            padding: 0,
            ...(mobile ? { width: "100vw" } : { minWidth: 500, width: "60%" }),
            ...(showExpandedChild ? { height: "100vh" } : {}),
          },
        }}
      >
        <DialogTitle>
          <Box
            className={`dragDialog`}
            sx={{
              display: "flex",
              alignItems: "center",
              ...(showExpandedChild ? { fontSize: "0.8rem" } : {}),
            }}
          >
            {showExpandedChild && (
              <Box marginX={2}>
                {expandedChild?.svg ?? null}
                {expandedChild?.icon && <Icon> {expandedChild?.icon} </Icon>}
                {expandedChild?.imgSrc && (
                  <LegendImage
                    fileName={expandedChild?.imgSrc}
                    alt={expandedChild?.label}
                    imgProps={{ height: "35px", width: "35px" }}
                  />
                )}
              </Box>
            )}
            {!showExpandedChild &&
              config?.titleIcon &&
              !config?.titleIconImg && (
                <Icon sx={{ mr: 1 }}>{config?.titleIcon}</Icon>
              )}
            {!showExpandedChild && config?.titleIconImg && (
              <img
                height="20px"
                width="20px"
                style={{ marginRight: "10px" }}
                src={config?.titleIconImg}
                alt={config?.titleIcon}
              />
            )}
            {showExpandedChild ? expandedChild?.label : config?.title}
            <Box flexGrow={1} />
            <IconButton
              onClick={showExpandedChild ? handleExpand : handleClose}
            >
              {!showExpandedChild ? <Close /> : <ExpandMore />}
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: 0,
            maxHeight: showExpandedChild ? "100vh" : "75vh",
          }}
        >
          {showExpandedChild ? (
            <Box p={0} bgcolor={"#3C3F41"} height={"95%"}>
              {expandedChild.component}
            </Box>
          ) : (
            <>
              <LegendList>{config?.items?.map(renderItem)}</LegendList>
              <Box my={2}>{children}</Box>
            </>
          )}
        </DialogContent>
      </Dialog>
    )
  );
};

export default forwardRef(LegendDialog);
