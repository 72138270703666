import { Box, Button, Checkbox, FormControlLabel, InputLabel } from "@mui/material";
import LegendDialog from "./legend/LegendDialog";
import appIntroConfig from "./legend/configs/appIntroConfig";

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { popDialog } from "../slices/dialogSlice";
import { updateProfile } from "../services/profileServices";
import { incrementDataRevision } from "../slices/revisionSlice";
import { useNavigate } from "react-router-dom";

const AppIntro = ({ userOpened = false }) => {
  const legendRef = useRef()
  const dispatch = useDispatch()
  const profileId = useSelector(({ user }) => user?.profileId)
  const navigate = useNavigate()

  const [dontShowAgain, setDontShowAgain] = useState(false)

  const handleClose = async () => {
    if (!userOpened && profileId) await updateProfile(profileId, { preferences: { dontShowHelp: dontShowAgain } })
    dispatch(incrementDataRevision({ event: "userRevision" }))
    dispatch(popDialog())
  }
  
  useEffect(() => {
    legendRef?.current?.open()
  }, [])
  
  const redirectToRoomJoin = () => {
    dispatch(popDialog())
    navigate("/room", { replace: true })
  }

  return <Box>
    <LegendDialog config={appIntroConfig} ref={legendRef} onClose={handleClose}>
      <Box display={"flex"} justifyContent={"center"} p={1}>
        <Button variant="bigfoot" onClick={redirectToRoomJoin} > Play </Button>
      </Box>
      {!userOpened && <Box>
        <FormControlLabel
        sx={{ml:2}}
          control={<Checkbox  checked={dontShowAgain} onChange={() => setDontShowAgain(prevValue => !prevValue)} />}
          label={<InputLabel>
            Don't show again
          </InputLabel>} />
      </Box>}
    </LegendDialog>
  </Box>
}

export default AppIntro