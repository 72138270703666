import { CloudUpload } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  LinearProgress,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import useFulfilledEventActionByHash from "../hooks/useFulfilledEventActionByHash";
import PromptDisplay from "../components/PromptDisplay";
import CollapsibleElement from "../components/CollapsibleElement";
import FileDisplay from "../components/FileDisplay";

const getInferenceCountFromText = (inferenceCountText) => {
  console.log(inferenceCountText?.split("\n")?.filter(Boolean)?.pop())
  const regexResult = /\d+/.exec(inferenceCountText?.split("\n")?.filter(Boolean)?.pop())
  return regexResult[0] 
}

const GameTime = (props) => {
  const [hash, setHash] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingState, setLoadingState] = useState("NONE");
  const [response, setResponse] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [file, setFile] = useState(null);
  const inputRef = useRef();

  useFulfilledEventActionByHash(
    hash,
    (res) => {
      setResponse(res?.items);
      setLoadingState("FINISHED");
      setLoading(false);
    },
    (metadata) => {
      setMetadata(metadata);
    }
  );

  function handleInputClick() {
    inputRef?.current?.click();
  }

  function handleInputChange(ev) {
    const uploadedFile = ev.target.files[0];
    setFile(uploadedFile);
    setLoadingState("UPLOADING");
    uploadFile(uploadedFile);
    setMetadata(null)
  }

  async function uploadFile(file) {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("files", file);
      const response = await fetch(
        `${process.env.REACT_APP_WEBSOCKET_URL}/gametime`,
        {
          method: "POST",
          body: formData,
        }
      );
      const json = await response.json();
      setLoadingState("PROMPTING");
      setHash(json.hash);
    } catch (err) {}
  }

  useEffect(() => {
    if(metadata?.prompt){
      setLoadingState("INFERING");
    }
}, metadata?.prompt)

  return (
    <Container maxWidth="md">
      <Box
        p={5}
        display="flex"
        justifyContent={"center"}
        flexDirection={"column"}
        gap={"1rem"}
      >
        <Typography align="center" color={"white"} variant="h4">
          Upload your file
        </Typography>
        <Button
          variant="contained"
          startIcon={<CloudUpload />}
          onClick={handleInputClick}
        >
          Upload
          <input
            ref={inputRef}
            type="file"
            hidden="true"
            onChange={handleInputChange}
            multiple={false}
          ></input>
        </Button>
        <Typography align="center" color={"white"} variant="h6">
          Accepted file types: PDF, XLSX
        </Typography>
      </Box>
      {loadingState !== "NONE" && (
        <LoadingStepper loadingState={loadingState} />
      )}
      {loading && <Loading />}
      {file && (
        <CollapsibleElement title={"File"}>
          <FileDisplay file={file} />
        </CollapsibleElement>
      )}
      {metadata?.prompt && (
        <CollapsibleElement title={`Prompt data - ${metadata?.prompt?.split("\n")?.length} line(s)`}>
          <PromptDisplay prompt={metadata?.prompt} />
        </CollapsibleElement>
      )}
      {metadata?.countInference && (
        <CollapsibleElement title={`Inference: Event count - ${getInferenceCountFromText(metadata?.countInference)} event(s)`}>
          <Typography color={"white"} variant="h6">
            <pre class="!whitespace-pre-wrap !break-words" style={{whiteSpace: 'pre-wrap'}}>

            {metadata?.countInference}
            </pre>
          </Typography>
        </CollapsibleElement>
      )}
      {response && (
        <CollapsibleElement title={`Inference: Records - ${response?.length} record(s)`}>
          <ResponseContent response={response} />
        </CollapsibleElement>
      )}
      <Box mb={5} />
    </Container>
  );
};

const LoadingStepper = ({ loadingState }) => {
  const steps = useMemo(() => ["UPLOADING", "PROMPTIGN", "INFERING", "FINISHED"], []);
  const [activeStep, setActiveStep] = useState(-1);
  useEffect(() => {
    setActiveStep(steps.findIndex((step) => step === loadingState) + (loadingState === "FINISHED"));
    console.log({loadingState})
  }, [loadingState, steps]);

  return (
    <Box mb={4}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step) => (
          <Step key={step}>
            <StepLabel> {step}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

const Loading = () => {
  return (
    <Box mb={4} display={"flex"} justifyContent={"center"}>
      <CircularProgress size={"6rem"} />;
    </Box>
  );
};

const ResponseContent = ({ response }) => {
  const header = [
    "Date",
    "Time Start",
    "Time End",
    "Location",
    "Field",
    "Snacks",
    "Game Type",
  ];
  const fields = [
    "date",
    "timeStart",
    "timeEnd",
    "location",
    "field",
    "snacks",
    "gameType",
  ];
  return response ? (
    <Box width={"100%"} mb={3}>
      <Paper sx={{ backgroundColor: "rgba(0,0,0,0.4)" }}>
        <TableContainer component={Paper}>
          <Table>
            <TableRow>
              {header.map((h) => (
                <TableCell> {h}</TableCell>
              ))}
            </TableRow>
            {response.map((item) => (
              <TableRow>
                {fields.map((field) => (
                  <TableCell>{item[field]}</TableCell>
                ))}
              </TableRow>
            ))}
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  ) : null;
};

export default GameTime;
