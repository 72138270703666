import { Box, Card, CardMedia, Typography, useMediaQuery, useTheme } from "@mui/material"
import Carousel from "react-material-ui-carousel"

// const items = [
//   { id: 1, src: "/assets/gif/choose.gif", title: "Choose", description: "There is no turns! Just tap two squares trying to match" },
//   { id: 2, src: "/assets/gif/match.gif", title: "Match", description: "If the squares have a star ⭐️, that means the match is yours" },
//   { id: 3, src: "/assets/gif/enemy.gif", title: "Enemy", description: "If the squares have a skull 💀, that means the match is from an enemy" },
//   { id: 4, src: "/assets/gif/win.gif", title: "Win", description: "Remember, there is no turns, try to get matches as fast as posible to be the winner!" },
// ]

const TutorialCarousel = ({items}) => {

  return (<Box display={"flex"} justifyContent={"center"} alignItems={"top"} >
    <Carousel sx={{width: "100%", paddingX: 3, mb: 1}} interval={10000}
    prev={(prev, active) => {
      // console.log({prev, active})
    }}
    next={(next, active) => {
      // console.log({next, active})
    }}
    >
      {items.map(item => (
        <TutorialStep key={item?.id} src={item?.src}
          title={item?.title}
          description={item?.description} />
      ))}
    </Carousel>
  </Box>)
}

const TutorialStep = ({ src, title, description }) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("sm"))


  return (<Box display={"flex"} flexDirection={"column"} alignItems={"center"} mt={2}>
    <Typography variant="h5">
      {title}
    </Typography>
    <Typography align="center" sx={{ maxWidth: mobile ? "100%" : "60%" }}>
      {description}
    </Typography>
    <Card sx={{ mb: 2, mt: 1 }}>
      <CardMedia component={"img"} height={"500"} image={src} alt="gif" />
    </Card>
  </Box>)
}


export default TutorialCarousel