const config = {
  title: "Legend",
  items: [
    {
      label: "Instant Bonus",
      imgSrc: "stack-of-coin-6673804-5526420.png",
      description: "Receive 500 in-game coins as soon as you register",
    },
    {
      label: "AI-Generate avatars",
      imgSrc: "winter-hat-animal-4454058-3692829-regular.png",
      description:
      "Unlock AI avatars uniquely created based on your player's name",
    },
    {
      label: "Save your info",
      imgSrc: "trophy-7342112-6019875.png",
      
      description:
        "Never worry about losing your hard-earned progress, your game data is securely connected to the verified email",
    },
  ],
};
// const config = {
//   title: "Legend",
//   items: [
//     {
//       label: "Instant Bonus",
//       svg: (<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" >
//         <path d="m5,16l-2,-11l5.5,5l3.5,-6l3.5,6l5.5,-5l-2,11l-14,0m14,3c0,0.6 -0.4,1 -1,1l-12,0c-0.6,0 -1,-0.4 -1,-1l0,-1l14,0l0,1z" fill="currentColor" id="svg_1" />
//       </svg>),
//       description: "Receive 500 in-game coins as soon as you register, giving you a head start in the game.",
//     },
//     {
//       label: "AI-Generate avatars",
//       svg: (<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" >
//         <path d="m5,16l-2,-11l5.5,5l3.5,-6l3.5,6l5.5,-5l-2,11l-14,0m14,3c0,0.6 -0.4,1 -1,1l-12,0c-0.6,0 -1,-0.4 -1,-1l0,-1l14,0l0,1z" fill="currentColor" id="svg_1" />
//       </svg>),
//       description:
//         "Unlock the ability to generate custom avatars, uniquely created by our advanced AI system based on your chosen player name, ensuring a personalized and one-of-a-kind in-game identity.",
//     },
//     {
//       label: "Progress protection",
//       svg: (<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" >
//         <path d="m5,16l-2,-11l5.5,5l3.5,-6l3.5,6l5.5,-5l-2,11l-14,0m14,3c0,0.6 -0.4,1 -1,1l-12,0c-0.6,0 -1,-0.4 -1,-1l0,-1l14,0l0,1z" fill="currentColor" id="svg_1" />
//       </svg>),
//       description:
//         "Never worry about losing your hard-earned progress, as your game data is securely saved when you register",
//     },
//   ],
// };

export default config;
