import {
  Box,
  Button,
  Container,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const Lobby = ({
  lobbyList,
  lobbyActions,
  lobbyTableTitle,
  lobbyTable,
  TableFallbackComponent,
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Container maxWidth="sm">
      <Box
        p={mobile ? 2 : 5}
        display="flex"
        flexDirection={"column"}
        gap={"1rem"}
      >
        <LobbyActions lobbyActions={lobbyActions} />
        <Divider />
        <Typography
          variant={mobile ? "h4" : "h3"}
          align="center"
          color={"grey.200"}
        >
          {lobbyTableTitle}
        </Typography>
        <LobbyTable
          lobbyList={lobbyList}
          lobbyTable={lobbyTable}
          TableFallbackComponent={TableFallbackComponent}
        />
      </Box>
    </Container>
  );
};

const LobbyTableRow = ({ dataItem, bodyItems, tableKey, rowSx }) => {
  return (
    <TableRow key={dataItem[tableKey]} sx={rowSx ?? {}}>
      {bodyItems.map((bodyItem, cellIndex) => (
        <TableCell
          key={`${dataItem[tableKey]}_${cellIndex}`}
          align={bodyItem.align || "left"}
          sx={bodyItem.sx || {}}
        >
          {bodyItem.type === "component"
            ? bodyItem.component(dataItem)
            : dataItem[bodyItem.key]}
        </TableCell>
      ))}
    </TableRow>
  );
};

const LobbyTableHeader = ({ headerItems }) => {
  return (
    <TableHead>
      <TableRow>
        {headerItems.map((headerItem) => (
          <TableCell key={headerItem.key}>{headerItem.value}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const LobbyTable = ({ lobbyList, lobbyTable, TableFallbackComponent }) => {
  const { header, body, tableKey } = lobbyTable;

  const rowSx = body?.rowSx;

  return (
    <Box sx={{ p: 2, justifyContent: "center" }}>
      {lobbyList?.length ? (
        <Table>
          {header?.items && <LobbyTableHeader headerItems={header.items} />}
          <TableBody>
            {lobbyList.map((dataItem) => (
              <LobbyTableRow
                key={dataItem[tableKey]}
                dataItem={dataItem}
                bodyItems={body.items}
                tableKey={tableKey}
                rowSx={rowSx}
              />
            ))}
          </TableBody>
        </Table>
      ) : (
        TableFallbackComponent || <Typography>Lobby is empty</Typography>
      )}
    </Box>
  );
};

const LobbyActions = ({ lobbyActions }) => {
  return lobbyActions.map((action) => (
    <Box key={action?.key} display={"flex"} justifyContent={"center"}>
      <Button variant={action?.button?.variant} onClick={action?.onClick}>
        {action?.label}
      </Button>
    </Box>
  ));
};

export default Lobby;
