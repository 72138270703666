import { Avatar, Box, Button, Paper, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { popDialog, pushDialog } from "../../slices/dialogSlice";

const Unverified = (props) => {
  const userEgg = useSelector(({ user }) => user?.data?.image);
  const username = useSelector(({ user }) => user?.name);
  const dispatch = useDispatch();

  const onBenefitsClick = () => {
    dispatch(popDialog());
    dispatch(
      pushDialog({ id: "VERIFICATION_BENEFITS" })
    );
  };

  const handleClose = () => {
    dispatch(popDialog());
  }

  return (
    <>
      <Box p={2}>
        <Box display="flex" justifyContent="center" columnGap={1}>
          <Typography variant="h4" component="h4" align="center" sx={{ mb: 2 }}>
            You unverified egg
          </Typography>
          <Avatar
            alt={username?.toUpperCase()}
            style={{ borderRadius: "100%" }}
            src={
              userEgg
            }
          />
        </Box>
        <Paper sx={{ mb: 2 }}>
          <Box p={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <Box>
              <Typography variant="h6">
                <b>Seems like you aren't verified! </b>
                Verification has many special benefits, including generating Avatars!
                Wanna see for yourself?
              </Typography>
            </Box>
          </Box>
        </Paper>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={onBenefitsClick}
          sx={{ mt: 3 }}
        >
          See Benefits!
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={handleClose}
          sx={{mt: 1}}
        >
          Maybe later
        </Button>
      </Box>
    </>

  );
}

export default Unverified