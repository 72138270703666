import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";

const ConfirmationDialog = ({
  open,
  setOpen,
  dialogProps,
  onAccept,
  onCancel,
  children,
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    onCancel?.()
    handleClose()
  }
  const handleAccept = () => {
    onAccept?.()
    handleClose()
  }

  return (
    <Dialog open={open} onClose={handleClose} {...dialogProps}>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleCancel}> Cancel </Button>
        <Button variant="contained" onClick={handleAccept}> Accept </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
