import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { shiftSnackbar } from "../../slices/snackbarSlice";

const SnackbarStack = () => {
  const snackbarStack = useSelector((state) => state.snackbar.stack);
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const action = (snackbarId) => (
      <IconButton
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        <Icon color="primary">close</Icon>
      </IconButton>
    );
    const lastSnackbar = snackbarStack[snackbarStack.length - 1];
    if (lastSnackbar) {
      enqueueSnackbar(lastSnackbar?.message, {
        variant: lastSnackbar?.variant,
        action,
      });
      setTimeout(() => {
        dispatch(shiftSnackbar());
      }, 10000);
    }
  }, [snackbarStack, enqueueSnackbar, dispatch, closeSnackbar]);

  return null;
};

export default SnackbarStack;
