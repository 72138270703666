import { Box, Button, LinearProgress, Link, Typography } from "@mui/material"
import { useForm } from "react-hook-form"
import FormTextField from "../form/FormTextField"
import FormCheckbox from "../form/FormCheckbox"
import FormSelect from "../form/FormSelect"
import { useDispatch, useSelector } from "react-redux"
import { createFeedback, evaluateFeedback } from "../../services/feedbackServices"
import { useState } from "react"
import { useWorker } from "../WorkerContext"
import { openSnackbar } from "../../slices/snackbarSlice"
import { categoryAdjectives } from "./moderation"


const FeedbackForm = () => {
  const profileId = useSelector(({ user }) => user.profileId)
  const [submitted, setSubmitted] = useState(false)
  const socketWorker = useWorker()
  const dispatch = useDispatch()
  const { control, handleSubmit, reset, formState: { isSubmitting, errors }, setError } = useForm({
    mode: "onSubmit", defaultValues: {
      title: "",
      description: "",
      anonymous: false,
      type: "NEW_IDEA"
    }
  })

  const validateFeedback = async (feedback) => {
    const evaluation = await evaluateFeedback(feedback)
    const isValid = !evaluation?.flagged && !evaluation?.profanity?.profane
    const feedbackError = buildErrorMessage(evaluation)
    return { isValid, feedbackError }
  }

  const buildErrorMessage = (feedbackError) => {
    if (feedbackError?.profanity?.profane) return "The feedback is not acceptable as it contains profane words"
    const flaggedCategories = Object.keys(feedbackError?.categories ?? {}).filter(category => feedbackError?.categories?.[category])
    const uniqueAdjectives = Array.from(new Set(flaggedCategories.map(category => categoryAdjectives[category])))

    const errorMessage = uniqueAdjectives.reduce((message, adjective, index) => {
      const initialIndex = 0
      const lastIndex = uniqueAdjectives.length - 1
      const prefix = index === initialIndex ? " " : index === lastIndex ? ", and " : ", "

      return `${message}${prefix}${adjective}`
    }, "The feedback is not acceptable as it contains content that is")
    return `${errorMessage}. Please refrain from posting such content.`
  }

  const onSubmit = async (model) => {
    const feedbackData = {
      profile: profileId, ...model
    }
    const { isValid, feedbackError } = await validateFeedback(`${feedbackData?.title} ${feedbackData?.description}`)
    if (isValid) {
      setSubmitted(true)
      await createFeedback(feedbackData)
      socketWorker.postMessage({ type: "GLOBAL_TRIGGER_REVISION", payload: { revision: "feedbackRevision" } });
      dispatch(openSnackbar({ message: "Feedback submitted", variant: "success" }))
    } else {
      setError("root", { type: "custom", message: feedbackError })
    }
  }

  const handleResetForm = () => {
    setSubmitted(false)
    reset();
  }

  const typeOptions = [
    { label: "New idea", value: "NEW_IDEA" },
    { label: "Improvement", value: "IMPROVEMENT" },
    { label: "Problem", value: "PROBLEM" },
  ]

  return (<Box display={"flex"} flexDirection={"column"} p={2}>
    <FormTextField viewMode={submitted} control={control} name="title" label="Title" required={"Title is required"} maxLength={100} />
    <FormTextField viewMode={submitted} control={control} name="description" label="Description" required={"Description is required"} maxLength={1000} multiline rows={5}/>
    <FormSelect viewMode={submitted} control={control} options={typeOptions} name="type" label="Type" />
    <FormCheckbox viewMode={submitted} control={control} name="anonymous" label="Anonymous" />
    {isSubmitting && <>
      <Typography align="center">
        Validating feedback
      </Typography>
      <LinearProgress />
    </>
    }
    <Button disabled={submitted} variant="bigfoot" sx={{ mt: 1 }} onClick={handleSubmit(onSubmit)}>
      submit
    </Button>
    <Typography color={"error"} sx={{ mt: 1 }}>
      {errors?.root?.message}
    </Typography>
    {submitted && <Link sx={{ mt: 1 }} onClick={handleResetForm}>Submit a new Feedback</Link>}
  </Box>)
}

export default FeedbackForm