import { Box, Button, SwipeableDrawer, Typography } from "@mui/material"
import { useState } from "react"
import FeedbackForm from "./FeedbackForm"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

const Feedback = () => {
  const [open, setOpen] = useState(false)

  const feedbackEnabled = useSelector(({user}) => !!user?.data?.options?.feedbackEnabled)
  const navigate = useNavigate()

  const handleOpen = () => {
    if (!open) {
      setOpen(true)
    }

  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleFeedbackPage = () => {
    navigate("/feedback", { replace: true })
    setOpen(false)
  }

  return (<>
    {feedbackEnabled && <Box position={"fixed"} display={"flex"} justifyContent={"flex-end"} bottom={"27%"} right={0} sx={{ transform: "translateX(calc(50% - 21px)) rotate(-90deg)" }}>
      <Button variant="contained" color="inherit" style={{ filter: "grayscale(100%)", }} onClick={handleOpen} >
        <Box display={"flex"} alignItems={"center"}>

          <img src="/assets/img/megaphone-7296631-6167168.png" width={"30px"} height={"30px"} alt="" />
          <Typography> Feedback </Typography>
        </Box>

      </Button>
    </Box>}
    <SwipeableDrawer anchor="right" open={open} onOpen={handleOpen} onClose={handleClose} PaperProps={{sx : {maxWidth:"45%"}}}>
      <Button onClick={handleFeedbackPage}> Go to feedback </Button>
      <FeedbackForm />
    </SwipeableDrawer>
  </>)
}

export default Feedback