import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useWorker } from "../../components/WorkerContext";
import { popDialog, pushDialog } from "../../slices/dialogSlice";
import { resetTournament } from "../../slices/tournamentSlice";
import { useNavigate } from "react-router-dom";

const TournamentListActions = ({ item }) => {
  const userName = useSelector(({ user }) => user.name);
  const profileId = useSelector(({ user }) => user.profileId);
  const socketWorker = useWorker();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const buildTitle = (matchName) => `Tournament ${matchName}`;

  const leaveRoom = () => {
    socketWorker.postMessage({
      type: "TOURNAMENT_LEAVE",
      payload: {
        tournamentName: item.id,
        userName,
      },
    });
    dispatch(popDialog());
    dispatch(resetTournament());
  };

  const redirectToTournamentLobby = () => {
    dispatch(popDialog());
    navigate(`/tournament/${item.id}/lobby`);
  };

  const joinTournament = () => {
    socketWorker.postMessage({
      type: "TOURNAMENT_JOIN",
      payload: {
        tournamentName: item.id,
        userName,
        profileId,
      },
    });
    dispatch(
      pushDialog({
        id: "MATCH_WAIT_LIST",
        props: {
          matchName: item.id,
          titleBuilder: buildTitle,
          onExit: leaveRoom,
          onPlayersReady: redirectToTournamentLobby,
          selector: ({ tournament }) => tournament,
          options: {
            hideInvite: true,
          },
          mapper: {
            users: "users",
            maxUsers: "options.maxUsers",
          },
        },
        dialogProps: {
          fullWidth: true,
        },
        options: { disableBackdropClick: true, disableEscapeKey: true },
      })
    );
  };

  return item?.status === "OPEN" ? (
    <Button variant="outlined" onClick={joinTournament}>
      JOIN
    </Button>
  ) : null;
};

export default TournamentListActions;
