import { Box, Button, DialogTitle, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { popDialog, pushDialog } from "../../slices/dialogSlice";
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import WaitingView from "./WaitingView";
import useMappedState from "../../hooks/useMappedState";

const PlayersWaiting = (props) => {
  const {
    selector,
    mapper,
    onExit,
    onPlayersReady,
    headerTitle,
    titleBuilder,
    matchName,
    options = {},
  } = props;
  const waitingState = useMappedState(selector, mapper);
  const { hideInvite } = options;
  const { users, maxUsers } = waitingState;

  const usersInRoom = _.size(users);

  useEffect(() => {
    if (usersInRoom && usersInRoom >= maxUsers) {
      setTimeout(() => {
        onPlayersReady?.();
      }, 700);
    }
  }, [maxUsers, onPlayersReady, usersInRoom, waitingState]);

  return (
    <Box>
      <WaitingHeader
        headerTitle={headerTitle}
        onExit={onExit}
        titleBuilder={titleBuilder}
        matchName={matchName}
      />
      <WaitingView
        matchName={matchName}
        users={users}
        maxUsers={maxUsers}
        hideInvite={hideInvite}
      />
    </Box>
  );
};

const WaitingHeader = ({ headerTitle, onExit, titleBuilder, matchName }) => {
  const dispatch = useDispatch();
  const handleExitRoom = () => {
    onExit?.();
    dispatch(popDialog());
  };

  return (
    <DialogTitle>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography>
          {headerTitle ?? (matchName && titleBuilder?.(matchName))}
        </Typography>
        <Button onClick={handleExitRoom}>Close</Button>
      </Box>
    </DialogTitle>
  );
};

export default PlayersWaiting;
