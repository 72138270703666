import feedbackPalette from "./components/feedback/feedbackPallete";

const theme = {
  palette: {
    mode: "dark",
    ...feedbackPalette,
  },
  breakpoints: {
    values: {
      xss: 0,
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: [
      "Tektur",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  components: {
    MuiInputLabel: {
      variants: [
        {
          props: {
            variant: "bigfoot",
          },
          style: {
            fontSize: "2rem",
            left: "2rem",
            top: "-0.9rem",
            '@media (max-width:380px)': {
              fontSize: '1.3rem',
            },
          },
        }
      ],
    },
    MuiTextField: {
      variants: [
        {
          props: {
            variant: "filled",
          },
          style: {
            '& .MuiInputBase-input': {
              // fontSize: "sm: 1.3rem md: 3rem",
              cursor: 'pointer',
              textAlign: 'center',
              padding: '1rem 10px',
            }
          },
        },
      ]
    },
    MuiButton: {
      variants: [
        {
          props: {
            variant: "bigfoot",
          },
          style: {
            fontSize: "2rem",
            backgroundColor: "#FA8BFF",
            color: "black",
            backgroundImage:
              "linear-gradient(45deg, #FA8BFF 0%, #2BD2FF 52%, #2BFF88 90%)",
            '@media (max-width:380px)': {
              fontSize: '1.3rem',
            },
          },
        },
        {
          props: {
            variant: "bigfoot-outline",
          },
          style: {
            fontSize: "1.42rem",
            color: "#FA8BFF",
            border: '1px solid #FA8BFF',
            '@media (max-width:380px)': {
              fontSize: '.9rem',
            },
          },
        },
        {
          props: {
            variant: "contained",
          },
          style: {
            fontSize: "1.7rem",
            '@media (max-width:380px)': {
              fontSize: '1.3rem',
            },
          },
        },
      ],
      styleOverrides: {
        contained: {
          "&$disabled": { // Target the disabled state of the contained variant
            fontSize: "1.7rem",
            '@media (max-width:380px)': {
              fontSize: '1.3rem',
            },
          },
        },
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: "0.8rem 0",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          textAlign: "center",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          textAlign: "center",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "2.25rem",
          justifyContent: "center",
        },
      },
    },
  },
};

export default theme;
