import { Paper } from "@mui/material";
import { Box } from "@mui/system";

const SimpleCard = ({ action, children, paperSx }) => {
  return (
    <Box onClick={action}>
      <Paper
        sx={{
          bgcolor: "#222",
          padding: 2,
          ...paperSx,
        }}
      >
        {children}
      </Paper>
    </Box>
  );
};

export default SimpleCard
