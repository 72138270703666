import { useDeviceSelectors } from "react-device-detect";

import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  OAuthProvider,
} from "firebase/auth";
import { Box, IconButton } from "@mui/material";
import { FaGoogle, FaApple } from "react-icons/fa";

const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");
appleProvider.addScope("email");
appleProvider.addScope("name");
googleProvider.addScope("email");

function isRunningStandalone() {
  return (
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone
  );
}

function SocialLogin({ onSubmit, setLoader, auth, disabled }) {
  // const analytics = getAnalytics(app);
  const [selectors] = useDeviceSelectors(window.navigator.userAgent);

  const { isIOS } = selectors;

  const loginWithProvider = (name, provider) => {
    setLoader?.(true);
    console.log(selectors, { isIOS, standAlong: isRunningStandalone() });

    if (isIOS && isRunningStandalone()) {
      signInWithRedirect(auth, provider)
        .then(async (result) => {
          onSubmit(result?.user?.email);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // const token = await auth.currentUser.getIdToken();
          // const credential = OAuthProvider.credentialFromResult(result);
          onSubmit(result?.user?.email);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return isIOS && isRunningStandalone() ? null : (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "flex-end" }}
    >
      <IconButton
        variant="contained"
        color="primary"
        onClick={() => loginWithProvider("google", googleProvider)}
        disabled={disabled}
      >
        <FaGoogle size="1.2em" />
      </IconButton>
      <IconButton
        variant="contained"
        color="primary"
        onClick={() => loginWithProvider("apple", appleProvider)}
        disabled={disabled}
      >
        <FaApple size="1.5em" />
      </IconButton>
    </Box>
  );
}

export default SocialLogin;
