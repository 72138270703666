import { TextField, Typography } from "@mui/material"
import { Controller } from "react-hook-form"

const FormTextField = ({ control, name, viewMode = false, required = false, maxLength = 1500, ...props }) => {
  return <Controller
    name={name}
    control={control}
    rules={{ required, maxLength: { value: maxLength, message: `Maximum ${maxLength} characters allowed`} }}
    render={({ field, fieldState }) => viewMode ? (
      <>
        <Typography variant="caption"> {props.label}</Typography>
        <Typography variant="h6"> {field.value}</Typography>
      </>
    ) : <TextField
      onChange={field.onChange}
      value={field.value}
      error={fieldState?.invalid}
      helperText={fieldState?.error?.message}
      {...props}
    />
    } />
}

export default FormTextField