import { createSlice } from "@reduxjs/toolkit";
const { v4: uuidv4 } = require("uuid")


const snackbarSlice = createSlice({
  name: "snackbar",
  initialState: {
    stack: [],
  },
  reducers: {
    openSnackbar: (state, action) => {
      const snackbarId = uuidv4()
      state.stack.push({ id: snackbarId, ...action.payload });
    },
    shiftSnackbar: (state) => {
      state.stack.shift()
    }
  },
});

export const snackbarReducer = snackbarSlice.reducer;
export const { openSnackbar, shiftSnackbar } = snackbarSlice.actions;
export default snackbarSlice;
