import { FormControl, InputLabel, Slider, Typography } from '@mui/material';
import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';

const FormSlider = forwardRef(
  (
    {
      control,
      name,
      label,
      options,
      viewMode,
      variant,
      step,
      marks,
      min,
      max,
      getAriaValueText,
      color,
      sx,
      ...props
    },
    ref
  ) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) =>
          viewMode ? (
            <>
              <Typography variant="caption"> {label}</Typography>
              <Typography variant="h6">{field.value}</Typography>
            </>
          ) : (
            <>
              <InputLabel> {label} </InputLabel>
              <FormControl>
                <Slider
                  aria-label={label}
                  variant={variant}
                  label={label}
                  color={color}
                  value={field.value}
                  onChange={field.onChange}
                  aria-labelledby="input-slider"
                  defaultValue={30}
                  getAriaValueText={getAriaValueText}
                  valueLabelDisplay="off"
                  step={step}
                  marks={marks}
                  min={min}
                  max={max}
                  ref={ref}
                  sx={sx}
                />
              </FormControl>
            </>
          )
        }
      />
    );
  }
);

export default FormSlider;
