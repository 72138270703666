import { Box, Container, Paper, Typography } from "@mui/material";
import PlayerScore from "../../components/tournament/PlayerScore";
import { useSelector } from "react-redux";
import { formatName } from "../../utils/stringUtils";
import { findCurrentLevel, getLevelLabel } from "../../utils/tournamentUtils";

const TournamentBracketsView = ({ levels }) => {
  const userName = useSelector(({ user }) => user.name);
  const tournamentWinner = useSelector(({ tournament }) => tournament.winner);
  const tournamentUsers = useSelector(({ tournament }) => tournament.users);
  const maxLevel = levels?.length - 1;
  const currentLevel = findCurrentLevel(levels, userName);
  return (
    <Container maxWidth="md" sx={{ mx: "auto", my: 5 }}>
      <Paper
        sx={{
          padding: 2,
          marginBottom: 2,
          px: { md: 5, xs: 0},
        }}
      >
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <Typography align="center" color={"white"} variant="h3">
            {getLevelLabel(currentLevel, maxLevel)}
          </Typography>

          <Box
            display="flex"
            height={"60vh"}
            overflow={"auto"}
            justifyContent={"center"}
            width={"100%"}
          >
            {levels.map((level, levelIndex) => {
              const playerCount = level.reduce((players) => players + 2, 0);
              const containerHeight = `${100 / playerCount}%`;
              const previousLevel = levelIndex ? levels[levelIndex - 1] : null;

              return (
                <>
                  <Box
                    key={"level" + levelIndex}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"space-around"}
                  >
                    {level.map((bracket) => {
                      const users = Object.values(bracket?.users ?? {});
                      return (
                        <>
                          {new Array(2).fill().map((__, i) => {
                            const previousBracketWinner =
                              previousLevel?.[i]?.winner;
                            const player = previousLevel
                              ? tournamentUsers?.[previousBracketWinner]
                              : users[i];
                            return (
                              <Player
                                bracket={bracket}
                                player={player}
                                key={`player_${i}-level${levelIndex}`}
                                allUsers={bracket.users}
                              />
                            );
                          })}
                        </>
                      );
                    })}
                  </Box>
                  <Box
                    sx={{ opacity: 0.5 }}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"space-around"}
                  >
                    {new Array(playerCount / 2).fill().map(() => (
                      <Box
                        height={containerHeight}
                        border={"solid 2px"}
                        borderLeft={"none"}
                        width={"20px"}
                      />
                    ))}
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"space-around"}
                    sx={{ opacity: 0.5 }}
                  >
                    {new Array(playerCount / 2).fill().map(() => (
                      <Box height={"0px"} border={"solid 1px"} width={"20px"} />
                    ))}
                  </Box>
                </>
              );
            })}
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-around"}
            >
              <Player
                bracket={{ winner: tournamentWinner }}
                player={tournamentUsers?.[tournamentWinner]}
                allUsers={tournamentUsers}
              />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

const Player = ({ player, allUsers, bracket }) => {
  const userName = useSelector(({ user }) => user.name);
  const lead =
    bracket?.winner && player?.name && bracket.winner === player?.name;
  return (
    <Box display={"flex"} flexDirection={"column"} gap={1.5}>
      <div style={bracket?.finished && !lead ? { filter: "grayscale(1)" } : {}}>
        <PlayerScore
          allUsers={allUsers}
          player={player}
          isLead={lead}
          variant="gameover"
        />
      </div>
      <Typography
        align="left"
        fontWeight={player?.name === userName ? "bold" : ""}
        sx={[
          { color: "whitesmoke" },
          { fontSize: "0.75rem" },
        ]}
      >
        {player?.name && formatName(player?.name)}
      </Typography>
    </Box>
  );
};

export default TournamentBracketsView;
