import { createSlice } from "@reduxjs/toolkit";

export const gameInitialState = {
  players: {},
  board: {
    cards: [],
    powerUpCard: {}
  },
  winners: [],
  lead: null,
  started: false,
  metadata: {
    startedAt: null
  }
};

const gameSlice = createSlice({
  name: "game",
  initialState: gameInitialState,
  reducers: {
    updateGame: (state, action) => {
      return action.payload;
    },
    updateCard: (state, action) => {
      const selectedCard = action.payload;
      const { cards } = state.board;
      const card = cards.find((card) => card.index === selectedCard.index);
      card.selectedBy = selectedCard.selectedBy;
      card.matchedBy = selectedCard.matchedBy;
    },
    select: (state, action) => {
      const selectedCard = action.payload;
      const { cards } = state.board;
      const card = cards.find((card) => card.index === selectedCard.index);
      card.selectedBy = action.payload.selectedBy;
    },
    startGame: (state, action) => {
      return { ...state, started: true };
    },
    updateScore: (state, action) => {
      const player = state.players[action.payload.name];
      player.score = action.payload.score;
    },
    updateWinners: (state, action) => {
      state.winners = action.payload;
    },
    resetGame: () => {
      return gameInitialState;
    },
    gameLead: (state, action) => {
      const leadPlayer = action.payload;
      state.lead = leadPlayer;
    },
    addSelectedCard: (state, action) => {
      const { card, userName } = action.payload;
      const player = state.players[userName];
      player?.selected?.push(card.index);
    },
    resetSelected: (state, action) => {
      const player = state.players[action.payload];
      player.selected = [];
    },
    lockPlayer: (state, action) => {
      const player = state.players[action.payload];
      player.playerLock = true;
    },
    unlockPlayer: (state, action) => {
      const player = state.players[action.payload];
      player.playerLock = false;
    },
    freezePlayer: (state, action) => {
      const player = state.players[action.payload];
      player.affectedBy.freeze = true
    },
    addPowerUp: (state, action) => {
      const {powerUpType, userName} = action.payload;      
      const player = state.players[userName]
      player.powerUp[powerUpType].count++
    },
    updatePowerUpCard: (state, action) => {
      const powerUpCard = action.payload;
      state.board.powerUpCard = powerUpCard
    }
  },
});

export const gameReducer = gameSlice.reducer;
export const {
  updateGame,
  startGame,
  resetGame,
  select,
  updateCard,
  updateScore,
  updateWinners,
  gameLead,
  lockPlayer,
  unlockPlayer,
  addSelectedCard,
  resetSelected,
  freezePlayer,
  addPowerUp,
  updatePowerUpCard,
} = gameSlice.actions;
export default gameSlice;
