import { useSelector } from "react-redux";
import { getUserFulfilledEvent } from "../services/userServices";
import React from "react";

const useFulfilledEventAction = (type, callback, hash, pollingTimeMs = 10000) => {
  const userId = useSelector(({ user }) => user?.userId);

  React.useEffect(() => {
    let intervalId;
    intervalId = setInterval(() => {
      (async () => {
        const { fulfilled, response } = await getUserFulfilledEvent(hash ?? userId, type);
        if (fulfilled) {
          callback?.(response);
        }
      })();
    }, pollingTimeMs);

    return () => clearInterval(intervalId);
  }, [userId, callback, hash]);
};

export default useFulfilledEventAction;