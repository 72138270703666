import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { Typography } from "@mui/material";

import Lobby from "../../components/lobby/Lobby";
import { useWorker, useWorkerListener } from "../../components/WorkerContext";
import TournamentListActions from "./TournamentListActions";

const TournamentPage = () => {
  const navigate = useNavigate();
  const socketWorker = useWorker();

  const [tournaments, setTournaments] = useState({});

  const handleWorkerMessage = (event) => {
    if (event.data.type === "TOURNAMENTS_LIST") {
      const tournaments = event.data.payload;
      setTournaments(tournaments);
    }
  };

  useWorkerListener(handleWorkerMessage);

  useEffect(() => {
    socketWorker.postMessage({ type: "TOURNAMENTS_GET" });
  }, [socketWorker]);

  const handleCreate = () => {
    navigate("/tournament/create", { replace: true });
  };

  const actions = [
    {
      key: "create_tournament",
      label: "Start Tournament",
      onClick: handleCreate,
      button: {
        variant: "bigfoot",
      },
    },
  ];

  const lobbyTable = {
    tableKey: "id",
    header: {
      items: [
        { key: "header_game", value: "Game" },
        { key: "header_players", value: "Players" },
        { key: "header_empty", value: "" },
      ],
    },
    body: {
      items: [
        {
          key: "id",
          sx: {
            fontWeight: "bold",
          },
        },
        {
          key: "players",
          type: "component",
          component: (item) => {
            return `${_.size(item.users)} / ${item?.options?.maxUsers}`;
          },
        },
        {
          key: "actions",
          type: "component",
          align: "right",
          component: (item) => <TournamentListActions item={item}/>,
        },
      ],
    },
  };

  return (
    <Lobby
      lobbyActions={actions}
      lobbyTableTitle={"Pick a tournament to join"}
      TableFallbackComponent={<EmptyTournamentTable />}
      lobbyList={Object.values(tournaments)}
      lobbyTable={lobbyTable}
    />
  );
};

const EmptyTournamentTable = () => {
  return (
    <>
      <Typography variant="h5" textAlign={"center"} color="white">
        No tournaments available
      </Typography>
      <Typography variant="body2" textAlign={"center"} color="white">
        Start one and invite your friends!
      </Typography>
    </>
  );
};

export default TournamentPage;
