import "./reactions/Reactions.css";
import "./powerups/PowerUpStatus.css";

import { Avatar, Badge, Icon } from "@mui/material";
import { Box } from "@mui/system";
import { memo, useRef } from "react";
import { useSelector } from "react-redux";
import { isBase64 } from "../utils/stringUtils";
import { useWorkerListener } from "./WorkerContext";
import reactionDictionary from "./reactions/reactionDictionary";
import {
  powerUpStatusImageURLs,
  statusAnimationDurations,
} from "./powerups/powerUpUtils";

import { AnimatePresence, motion } from "framer-motion";

const PlayerScore = ({ player, isLead, variant }) => {
  const user = useSelector(({ user }) => user);
  const avatar = player?.avatar;

  const game = useSelector(({ game }) => game);
  const gameTied = game.winners.length > 1;

  const avatarRef = useRef();
  const statusRef = useRef();

  // Variants
  const isGameOver = variant === "gameover";
  const gameOverColor = player.name === user.name ? "green" : "red";
  const gameOverText = player.name === user.name ? "⭐️" : "💀";

  const handleWorkerMessage = async (event) => {
    if (event.data.type === "GAME_REACTION") {
      const { userName, reactionCounts } = event.data.payload;
      if (player.name === userName) {
        for (let reactionName in reactionCounts) {
          const reactionCount = reactionCounts[reactionName];
          for (let i of new Array(reactionCount).fill()) {
            createReactionAnimation(reactionName);
            await new Promise((resolve) => setTimeout(resolve, 200));
          }
        }
      }
    } else if (event.data.type === "GAME_POWERUP_STATUS") {
      const { affected, status } = event.data.payload;
      if (affected.includes(player.name)) createStatusEffectOverlay(status);
    }
  };

  useWorkerListener(handleWorkerMessage);

  const createReactionAnimation = (reactionName) => {
    const reactionElement = document.createElement("span");
    reactionElement.className = "floating-reaction";
    reactionElement.innerText =
      reactionDictionary[reactionName] ?? reactionName;

    reactionElement.style.right = `0px`;
    reactionElement.style.bottom = `0px`;

    const randomY = Math.floor(Math.random() * (80 - 45 + 1) + 45);
    reactionElement.style.setProperty("--randomY", randomY.toString());

    reactionElement.style.setProperty(
      "--randomDirection",
      Math.random() > 0.5 ? "1" : "-1"
    );

    avatarRef?.current?.appendChild(reactionElement);

    reactionElement.addEventListener("animationend", () => {
      avatarRef?.current?.removeChild(reactionElement);
    });
  };

  const createStatusEffectOverlay = (statusType) => {
    const overlay = document.createElement("span");
    overlay.className = "avatar-overlay";
    overlay.style.backgroundImage = `url(${powerUpStatusImageURLs[statusType]})`;
    overlay.style.backgroundColor = "rgba(0, 0, 0, 0.7)";

    statusRef.current.appendChild(overlay);

    setTimeout(() => {
      statusRef.current.removeChild(overlay);
    }, statusAnimationDurations[statusType]);
  };

  return !avatar ? null : (
    <Box
      display={"flex"}
      alignItems={"center"}
      sx={{ position: "relative" }}
      ref={avatarRef}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={1}
        mx={1}
        style={{ position: "relative" }}
      >
        <Badge
          color={"primary"}
          badgeContent={!isGameOver ? player?.score.toString() : gameOverText}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          slotProps={{
            badge: {
              style: {
                ...(isGameOver ? { backgroundColor: gameOverColor } : {}),
              },
            },
          }}
        >
          <Avatar
            alt={player?.name}
            ref={statusRef}
            style={{
              position: "relative",
              border: `2px solid ${
                player.name === user.name ? "green" : "red"
              }`,
              borderRadius: "100%",
            }}
            src={isBase64(avatar) ? `data:image/png;base64,${avatar}` : avatar}
          />
        </Badge>
        <AnimatePresence>
          {isLead && !gameTied && (
            <motion.div
              style={{
                display: "flex",
                position: "absolute",
                left: "0",
                marginTop: "-43px",
                marginLeft: "-15px",
                transform: "rotate(-20deg)",
                zIndex: 3000,
              }}
              animate={{
                y: [-200, 0],
                transition: { duration: 0.4, type: "spring", bounce: 0.1 },
              }}
              exit={{
                scale: 0.5,
                x: -200,
                rotate: -720,
                transition: { duration: 1 },
              }}
            >
              <img
                src={`/assets/img/game/crown_flat.png`}
                alt={"crown"}
                width={40}
                height={40}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    </Box>
  );
};

export default memo(PlayerScore);
