import { Button, TextField, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveInvitationCode } from "../../services/profileServices";
import { openSnackbar } from "../../slices/snackbarSlice";

const { v4: uuidv4 } = require("uuid")

function InviteButton({ matchName, urlBuilder }) {
  const profileId = useSelector(({ user }) => user.profileId)
  const textRef = useRef()
  const inviteCode = useRef(uuidv4())
//   const inviteURL = useMemo(() => `${window.location.origin}/room/join/${roomName}?ref=${inviteCode.current}`, [ roomName]);
  const inviteURL = urlBuilder(matchName, inviteCode.current)
  const dispatch = useDispatch()

  useEffect(() => {
    saveInvitationCode({ profileId, token: inviteCode.current })
  }, [profileId])

  const copyInvite = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Invite link",
          url: inviteURL
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      textRef.current?.select();
      textRef.current?.setSelectionRange(0, 99999);
      document.execCommand("copy");
      dispatch(openSnackbar({message: "Link copied!", variant: "success"}))
    }
  };



  return (
    <>
      {navigator.share ? (
        <Button onClick={copyInvite} variant="bigfoot" disabled={!inviteCode}>
          <Typography variant={"h6"} sx={{ color: "black" }}>

            Invite
          </Typography>
        </Button>
      ) : (
        <>
          <TextField fullWidth value={inviteURL} sx={{ display: "flex", justifyContent: "center" }}
            inputRef={textRef}
            InputProps={{
              readOnly: true,
              sx: {
                fontSize: "1.5rem"
              }

            }} />
          <Button variant='bigfoot' onClick={copyInvite} disabled={!inviteCode}>
            <Typography variant={"h5"}>
              COPY INVITE LINK
            </Typography>
          </Button>
        </>
      )}

    </>
  );
}

export default InviteButton;
