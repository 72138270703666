import { Box, Paper } from "@mui/material";
import { buildSrc } from "../../utils/stringUtils";
import SimpleCard from "./SimpleCard";
import { useNavigate } from "react-router-dom";


const UserSection = ({ profile, images }) => {
  const navigate = useNavigate();
  const source = buildSrc(profile?.image, profile?.userImgB64);
  const navigateToProfile = () => navigate("/profile", { replace: true });
  const redirectToLeaderboards = () => {
    navigate("/leaderboard", { replace: true });
  };

  return (
    <Paper
      sx={{
        bgcolor: "rgba(0,0,0,0.5)",
        backgroundImage: `url(${buildSrc(source)})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        height: "90%",
        padding: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 1,
      }}
    >
      <SimpleCard action={redirectToLeaderboards}> Leaderboards </SimpleCard>
      <Box flexGrow={1} />
      <SimpleCard action={navigateToProfile}> Avatars: {images?.length}</SimpleCard>
    </Paper>
  );
};

export default UserSection;
