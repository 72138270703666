import { Chip } from "@mui/material"
import feedbackTypes from "./feedbackTypes"

const FeedbackChip = ({ type }) => {
  
  // return <Chip label={feedbackTypes[type]} size="small" sx={{ ml: 1 }} color={`feedback.${type}`}/>
  return <Chip label={feedbackTypes[type]} size="small" sx={{ ml: 1 }} color={`feedback-${type}`}/>

}

export default FeedbackChip