import { Box } from "@mui/material";
import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { disconnect, pushMessage } from "../slices/connectionSlice";
import { useWorkerListener } from "./WorkerContext";

const STATUS_SIZE = 5;

const ConnectionStatus = () => {
  const dispatch = useDispatch();
  const connected = useSelector(({ connection }) => connection.connected);

  const handleWorkerMessage = useCallback((event) => {
    dispatch(pushMessage(JSON.stringify(event.data)))
    switch (event.data.type) {
      case "DISCONNECTED":
        dispatch(disconnect());
        break;
      default:
        break;
    }
  }, [dispatch]);

  useWorkerListener(handleWorkerMessage);

  return (
    <>
      <Box
        style={{ opacity: ".8" }}
        sx={{
          zIndex: -1,
          right: {
            xs: 20,
            sm: `calc(50% - ${STATUS_SIZE}px)`,
          },
          bottom: {
            xs: 10,
            sm: 0,
          },
        }}
        position="fixed"
        margin={1}
        fontSize={`${STATUS_SIZE}px`}
      >
        {connected ? "🟢" : "🔴"}{" "}
      </Box>
    </>
  );
};

export default memo(ConnectionStatus);
