import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import Logo from "./Logo";
import "./OuterErrorBoundary.css"

class OuterErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error("Uncaught error:", error, errorInfo);
  }

  reloadApp = () => {
    // Redirects to root
    window.location.href = "/";
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box
          p={5}
          display="flex"
          flexDirection={"column"}
          gap={"1rem"}
          justifyContent={"center"}
          alignItems={"center"}          
        >
          <Logo/>
          <Typography variant="h3" color="white" p={3} textAlign={"center"}>
            Something went wrong.
          </Typography>
          <Button variant="outlined" onClick={this.reloadApp}>
            Reload App
          </Button>
        </Box>
      );
    }

    // Normally, just render children
    return this.props.children;
  }
}

export default OuterErrorBoundary;
