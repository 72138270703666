import { Container, Paper } from "@mui/material";

const PageContainer = ({ children }) => {
  return (
    <Container maxWidth="md" sx={{ mx: "auto", my: 10 }}>
      <Paper
        sx={{
          padding: 2,
          marginBottom: 2,
          px: 10,
        }}
      >
        {children}
      </Paper>
    </Container>
  );
};

export default PageContainer;
