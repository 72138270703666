export const maxUsersOptions = [
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
];

export const cardPairsOptions = [
  { label: "4", value: 4 },
  { label: "8", value: 8 },
  { label: "14", value: 14 },
  { label: "16", value: 16 },
  { label: "32", value: 32 },
  { label: "48", value: 48 },
  { label: "64", value: 64 },
];

export const gameModeOptions = [
  { label: "Score", value: "score" },
  { label: "Roulette (WIP)", value: "roulette", disabled: true },
];
