import { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  Container,
  Paper
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { verifyToken } from "../services/profileServices";
import { useWorker } from "../components/WorkerContext";
import Logo from "../components/Logo";

const VerifyEmail = (props) => {
  const [searchParams] = useSearchParams();
  const socketWorker = useWorker();
  const [status, setStatus] = useState("VERIFYING");

  const token = searchParams.get("token");

  useEffect(() => {
    (async () => {
      let success;
      try {
        const { isVerified } = await verifyToken({ token });
        success = !!isVerified;
        if (isVerified) {
          setStatus("SUCCESS");
        } else {
          setStatus("ERROR");
        }
      } catch (err) {
        success = false;
        setStatus("ERROR");
      } finally {
        socketWorker?.postMessage({
          type: "EMAIL_VERIFY",
          payload: { success },
        });
      }
    })();

  }, [socketWorker, token]);

  const getVerificationContent = () => {
    switch (status) {
      case "VERIFYING":
        return (
          <>
            <Typography variant="h6">Verifying</Typography>
            <CircularProgress sx={{ mt: 2 }} />
          </>
        );
      case "SUCCESS":
        return (
          <>
            <div style={{ transform: "scale(70%)", margin: -20 }}>
              <Logo />
            </div>
            <Typography variant="h2" align="center">
              All done!
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }} align="center">
              Go back to the game
            </Typography>
          </>
        );
      case "ERROR":
      default:
        return (
          <>
            <Typography variant="h2" align="center">
              🙅🏻‍♀️
            </Typography>
            <Typography variant="h2" align="center">
              Nope!
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }} align="center">
              Something went wrong, try confirming again.
            </Typography>
          </>
        );
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            py: 4,
            px: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}
        >
          {getVerificationContent()}
        </Paper>
      </Box>
    </Container>
  );
};

export default VerifyEmail;
