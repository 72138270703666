import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { searchProfile } from "../services/profileServices";
import { updateUser } from "../slices/userSlice";
import { useWorker } from "./WorkerContext";
import { useLocation, useNavigate } from "react-router-dom";
import { redirectBlacklist } from "../consts/redirectConst";
import { doesUrlMatch } from "../utils/stringUtils";
import { pushMessage } from "../slices/connectionSlice";
import { resetTournament } from "../slices/tournamentSlice";
import useRedirectAction from "../hooks/useRedirectAction";

const Auth = (props) => {
  const connected = useSelector(({ connection }) => connection.connected);
  const savedUser = localStorage.getItem("user");
  const user = useSelector(({ user }) => user)
  const { userName } = savedUser ? JSON.parse(savedUser) : user?.name ? { userName: user.name } : {};
  const userRevision = useSelector(({ revision }) => revision.userRevision)
  const isAdmin = useSelector(({ user }) => user.isAdmin)
  const roomName = useSelector(({ room }) => room.id);
  const tournamentName = useSelector(({ tournament }) => tournament.id);

  const dispatch = useDispatch();
  const socketWorker = useWorker();
  const navigate = useNavigate()
  const location = useLocation()

  const handleUserUpdate = useCallback(async () => {
    const profile = await searchProfile({ username: user.name })
    if (!profile) return
    console.log("Updating profile")
    console.log({profile})
    dispatch(updateUser({
      userId: profile?.userId?._id?.toString(), //TODO: change userId to user
      profileId: profile?._id?.toString(),
      isAdmin: profile?.isAdmin,
      data: {
        wallet: profile?.wallet,
        imgB64: profile?.imgB64,
        image: profile?.image,
        email: profile?.userId?.email,
        preferences: profile?.preferences,
        options: profile?.options,
        reactions: profile?.reactions
      }
    }))
  }, [dispatch, user.name])

  useEffect(() => {
    dispatch(pushMessage("SENDING: " + JSON.stringify({
      type: "ROOM_RECONNECT",
      payload: { userName, roomName },
      connected
    })))
    if (connected) {
      if (roomName && userName) {
        socketWorker.postMessage({
          type: "ROOM_RECONNECT",
          payload: { userName, roomName },
        });
      }
    }
    // eslint-disable-next-line
  }, [connected, isAdmin]);


  useEffect(() => {
    if (userName) {
      dispatch(updateUser({ name: userName }));
    } else {
      const isBlacklisted = redirectBlacklist.some((url) => doesUrlMatch(url, location.pathname))
      if (isBlacklisted) return
      navigate("/", { replace: true })
    }
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    try {
      if (!user.name) return
      handleUserUpdate()
    } catch (error) {
      console.error(error)
    }
    // eslint-disable-next-line
  }, [userRevision, handleUserUpdate])

  useRedirectAction((prev, curr) => {
    if (
      doesUrlMatch("/tournament/:tId/lobby", prev) &&
      !doesUrlMatch("/:roomId/game", curr)
    ) {
      socketWorker.postMessage({
        type: "TOURNAMENT_LEAVE",
        payload: {
          tournamentName,
          userName: user.name,
        },
      });
      dispatch(resetTournament());
    }
  });

  return (
    <>
      {props.children}
    </>
  );
};

export default Auth;
