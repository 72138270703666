import { getUserFulfilledEventByHash } from "../services/userServices";
import React from "react";

const useFulfilledEventActionByHash = (
  hash,
  onResponse,
  onMetadataUpdate,
  pollingTimeMs = 2000
) => {
  React.useEffect(() => {
    let intervalId;
    intervalId = setInterval(() => {
      if (!hash) return;
      (async () => {
        const { fulfilled, response, metadata } =
          await getUserFulfilledEventByHash(hash);
        if (fulfilled && response) {
          onResponse?.(response);
        }
        if (metadata) {
          onMetadataUpdate?.(metadata);
        }
      })();
    }, pollingTimeMs);

    return () => clearInterval(intervalId);
  }, [hash]);
};

export default useFulfilledEventActionByHash;
