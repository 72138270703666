import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Link,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import SocialLogin from "../social-auth/SocialLogin";

import { sendVerification } from "../../services/profileServices";
import { popDialog, pushDialog } from "../../slices/dialogSlice";
import { useTheme } from "@emotion/react";
import { openSnackbar } from "../../slices/snackbarSlice";
import { reactContext } from "../../App";
import { useContext, useEffect } from "react";

const VerificationSendEmail = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const username = useSelector(({ user }) => user.name);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { auth } = useContext(reactContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    watch,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      email:
        process.env.NODE_ENV === "DEV" ? `${username}@project.mw` : undefined,
    },
  });

  const watchTerms = watch("terms");

  useEffect(() => {
    if (watchTerms) {
      clearErrors();
    }
  }, [watchTerms]);

  const emailValidation = {
    required: "Email is required",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: "Invalid email address",
    },
  };

  const handleSendEmail = async (data) => {
    try {
      const { token } = await sendVerification({
        userEmail: data.email?.toLowerCase(),
        username,
      });
      dispatch(popDialog());
      dispatch(
        pushDialog({
          id: "VERIFICATION_SENT",
          props: {
            userEmail: data.email?.toLowerCase(),
            token,
          },
          options: {
            disableBackdropClick: true,
          },
        })
      );
    } catch (err) {
      setError("email", {
        type: "custom",
        message: "Couldn't send verification email",
      });
    }
  };

  const onSocialSubmit =
    (dispatch, setIsProcessing, username) => async (email) => {
      const userEmail = email.toLowerCase();
      try {
        const { token } = await sendVerification({
          userEmail: email?.toLowerCase(),
          username,
          isSocial: true,
        });
        dispatch(popDialog());
        dispatch(
          pushDialog({
            id: "VERIFICATION_SOCIAL",
            props: {
              userEmail,
              token,
              returningUser: false,
            },
            options: {
              disableBackdropClick: true,
            },
          })
        );
      } catch (e) {
        dispatch(popDialog());
        dispatch(
          openSnackbar({
            message: e?.response?.data?.message,
            variant: "error",
          })
        );
      } finally {
        setIsProcessing(false);
      }
    };

  return (
    <Container maxWidth="sm">
      <Box
        py={5}
        px={isMobile ? 1 : 5}
        display="flex"
        flexDirection={"column"}
        gap={"1rem"}
        justifyContent={"center"}
      >
        <img
          src="/assets/img/pigeon-carrying-mail-6959410-5685631.png"
          width={`${isMobile ? "100px" : "150px"}`}
          height={`${isMobile ? "100px" : "150px"}`}
          alt="email pigeon"
          style={{ margin: "auto" }}
        />
        <Typography variant={"h4"} align="center">
          Verify
        </Typography>
        {!isMobile && (
          <Typography variant={"h5"} align="center">
            Use your email or use social logos below
          </Typography>
        )}
        <TextField
          label="Your email"
          {...register("email", emailValidation)}
          error={!!errors.email}
          helperText={errors.email?.message}
          onChange={() => {
            if (!watchTerms) {
              setError("email", {
                type: "custom",
                message: "Please accept terms and conditions below",
              });
            }
          }}
        />
        <Button
          disabled={!watchTerms}
          variant="contained"
          onClick={handleSubmit(handleSendEmail)}
          sx={{ mt: -2 }}
        >
          send
        </Button>
        {errors.terms && (
          <Typography color="error" textAlign={"center"}>
            {errors.terms.message}
          </Typography>
        )}
        <Divider sx={{ mb: 0 }}>or</Divider>
        <SocialLogin
          onSubmit={onSocialSubmit(dispatch, () => {}, username)}
          setLoader={() => {}}
          auth={auth}
          disabled={!watchTerms}
        />
        <PolicyAgreement register={register} errors={errors} />
      </Box>
    </Container>
  );
};

const PolicyAgreement = ({ register }) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            {...register("terms", {
              required: "You must accept the terms of service",
            })}
          />
        }
        label={
          <>
            {"I accept the "}
            <Link
              href="/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
            >
              {"terms of service"}
            </Link>
            {" and "}
            <Link
              href="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              {"privacy policy"}
            </Link>
          </>
        }
      />
    </FormGroup>
  );
};

export default VerificationSendEmail;
