import {
  Box, Container, LinearProgress, Typography
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkTokenExists } from "../../services/profileServices";
import { popDialog, pushDialog } from "../../slices/dialogSlice";
import { updateUser } from "../../slices/userSlice";

const VerificationSent = ({ userEmail, token, returningUser = false }) => {
  const dispatch = useDispatch();
  const pollingInterval = 5000;
  const pollingExpiration = 60000 * 5;
  const user = useSelector(({ user }) => user)

  const verifyEmailExists = async () => {
    try {
      const { username } = await checkTokenExists({ token });
      if (username) {
        if (!user.name) {
          dispatch(updateUser({ name: username }));
          localStorage.setItem("user", JSON.stringify({ userName: username }));
        }
        dispatch(popDialog());
        dispatch(
          pushDialog({
            id: "VERIFICATION_SUCCESFUL",
            props: {
              userEmail,
              returningUser
            },
          })
        );
      }
    } catch (err) {
      dispatch(popDialog());
      dispatch(
        pushDialog({
          id: "VERIFICATION_UNSUCCESFUL",
          props: {
            userEmail,
            returningUser
          },
        })
      );
    }
  };

  useEffect(() => {
    let intervalId, timeoutId
    verifyEmailExists();
    intervalId = setInterval(verifyEmailExists, pollingInterval);
    timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      dispatch(popDialog());
      dispatch(
        pushDialog({
          id: "VERIFICATION_UNSUCCESFUL",
          props: {
            userEmail,
            returningUser
          },
        })
      );
    }, pollingExpiration);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, []);


  return (
    <Container maxWidth="sm" style={{position: "relative"}}>
      <Box
        p={5}
        display="flex"
        flexDirection={"column"}
        gap={"1rem"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Typography variant={"h2"} align="center">
          ✉️
        </Typography>
        <Typography variant={"h4"} align="center">
          Check your email
        </Typography>
        <Typography variant={"h6"} align="center" sx={{ color: "#BBB" }}>
          {userEmail}
        </Typography>
        <Typography variant={"h6"} align="center">
          Verification email sent!
        </Typography>
        <Box position={"absolute"} bottom={0} width={"100%"}>
          <LinearProgress color="inherit" style={{height: "5px"}}/>

        </Box>
      </Box>
    </Container>
  );
};

export default VerificationSent;
