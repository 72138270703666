import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

const CollapsibleElement = ({title, children}) => {
  return <Accordion>
    <AccordionSummary expandIcon={<ExpandMore/>}>
      {title ?? "No title"}      
    </AccordionSummary>
    <AccordionDetails>
      {children}
    </AccordionDetails>
  </Accordion>
}

export default CollapsibleElement;