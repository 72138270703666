import { memo } from "react";
import "./VideoBackground.css";
import React from "react";
import { useSelector } from "react-redux";

function VideoBackground({ children }) {
  const deathmatchFilter = useSelector(
    ({ options }) => options.deathmatchFilter
  );
  const gameDeathmatch = useSelector(({ room }) => room?.options?.deathmatch);

  const isHorizontal = window.innerWidth > window.innerHeight; // TODO: check alternatives
  const videoSrc = `/assets/video/background_${
    isHorizontal ? "horizontal" : "vertical"
  }.mp4`;
  return (
    <div className="video-container">
      <video
        playsInline
        autoPlay
        muted
        loop
        className={`background-video ${
          deathmatchFilter || gameDeathmatch ? "red-filter" : ""
        }`}
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="content">{children}</div>
    </div>
  );
}

export default memo(VideoBackground);
