import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";

import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { incrementDataRevision } from "../slices/revisionSlice";
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { formatName, isBase64 } from "../utils/stringUtils";
import _ from "lodash";
import PlayerScore from "./PlayerScore";

const PlayersTable = ({ allUsers, game }) => {
  const user = useSelector(({ user }) => user);
  const tally = useSelector(({ room }) => room.tally);
  const lead = useSelector(({ game }) => game.lead);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const showPairs = useMediaQuery("(min-width:360px)");
  const matchFinished = useSelector(({ room }) =>
    Object.values(room?.tally ?? {}).some((player) => player?.win >= 3)
  );
  const deathmatch = useSelector(({ room }) => room?.options?.deathmatch);

  return (
    <Table sx={{ m: 0, p: { xs: 0 } }} size={mobile ? "small" : "medium"}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ fontSize: { xs: "0.7rem", sm: "1rem" } }}>
            Players
          </TableCell>
          {showPairs  && !deathmatch && (
            <TableCell
              align="center"
              sx={{ fontSize: { xs: "0.7rem", sm: "1rem" } }}
            >
              Pairs
            </TableCell>
          )}
          <TableCell
            align="center"
            sx={{ fontSize: { xs: "0.7rem", sm: "1rem" } }}
          >
            Wins
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.values(game?.players ?? {})
          .sort((a, b) => b.score - a.score)
          .map((player) => {
            const playerTally = tally[player.name];
            return (
              <TableRow key={player.name} sx={{ px: { xs: 0 }, py: { xs: 1 } }}>
                <TableCell sx={{ fontWeight: "bold", borderBottom: "none" }}>
                  <Box display={"flex"} alignItems={"center"} columnGap={1}>
                    <PlayerScore
                      player={player}
                      isLead={Boolean(lead && player.name === lead)}
                      variant="gameover"
                    />
                    <Typography
                      align="left"
                      fontWeight={player.name === user.name ? "bold" : ""}
                      sx={{ fontSize: { xs: "0.7rem", sm: "1rem" } }}
                    >
                      {formatName(player?.name)}
                    </Typography>
                  </Box>
                </TableCell>
                {showPairs && !deathmatch && (
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {player.score}
                  </TableCell>
                )}
                <TableCell align="center" sx={{ borderBottom: "none" }}>
                  {deathmatch ? "🏆".repeat(playerTally?.win ?? 0) : `${playerTally?.win ?? 0}`}
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

const GameResult = ({ game }) => {
  const allUsers = useSelector(({ room }) => room.users);
  const allUsersCopy = useMemo(
    () => JSON.parse(JSON.stringify(allUsers)),
    // eslint-disable-next-line
    []
  );
  const playersNumber = _.size(allUsersCopy);
  const dispatch = useDispatch();

  useEffect(() => {
    if (game?.winners?.length) {
      dispatch(incrementDataRevision({ event: "userRevision" }));
    }
  }, [game, dispatch]);

  return (
    true && (
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        height={"100%"}
        mt={2}
        sx={{ mb: (4 - playersNumber) * 1 }}
      >
        <PlayersTable allUsers={allUsersCopy} game={game} />
      </Box>
    )
  );
};

export default GameResult;
