import { Box, Switch, Typography } from "@mui/material";
import { Controller } from "react-hook-form";

const FormSwitch = ({
  control,
  name,
  label,
  viewMode = false,
  variant = null,
  active,
  inactive,
  typographySx = {},
  boxSx = {},
  switchSx = {},
  color,
  icons = {},
  ...props
}) => {
  const ActiveIconComponent = icons?.active?.img ? (
    <img
      src={icons.active.img.src}
      alt={icons.active.img.alt ?? ""}
      style={{ width: "30px", height: "30px" }}
    />
  ) : undefined;
  const InactiveIconComponent = icons?.inactive?.img ? (
    <img
      src={icons.inactive.img.src}
      alt={icons.inactive.img.alt ?? ""}
      style={{ width: "30px", height: "30px" }}
    />
  ) : undefined;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box
          display={"flex"}
          gap={1}
          width={"100%"}
          alignItems={"center"}
          sx={[
            boxSx,
            field.value && active?.boxSx,
            !field.value && inactive?.boxSx,
          ]}
        >
          <Switch
            onChange={(ev) => field.onChange(ev.target.checked)}
            checked={field.value}
            variant={variant}
            disabled={props.disabled || viewMode}
            color={color}
            {...(InactiveIconComponent ? { icon: InactiveIconComponent } : {})}
            {...(ActiveIconComponent
              ? { checkedIcon: ActiveIconComponent }
              : {})}
            {...props}
            sx={[
              switchSx,
              field.value && active?.switchSx,
              !field.value && inactive?.switchSx,
            ]}
          />
          <Typography
            sx={[
              typographySx,
              field.value && active?.typographySx,
              !field.value && inactive?.typographySx,
            ]}
          >
            {" "}
            {label}{" "}
          </Typography>
        </Box>
      )}
    />
  );
};

export default FormSwitch;
