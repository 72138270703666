import { Box, Paper } from "@mui/material";

const PromptDisplay = ({ prompt }) => {
  return prompt ? (
    <Box width={"100%"}>
      <Paper
        sx={{
          backgroundColor: "rgba(0,0,0,0.7)",
          color: "white",
          p: 5,
          overflow: "auto",
        }}
      >        
        <pre>{prompt}</pre>
      </Paper>
    </Box>
  ) : null;
};

export default PromptDisplay;
