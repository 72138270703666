import { Box, CircularProgress } from "@mui/material";

const LoadingView = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      position="fixed"
      top={0}
      width={"100%"}
      height={"100vh"}
    >
      <CircularProgress size={"3.5rem"} />
    </Box>
  );
};

export default LoadingView;
