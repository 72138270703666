import { Suspense, lazy } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import VerifyEmail from "../pages/VerifyEmail";
import ConnectionStatus from "./ConnectionStatus";
import LoadingView from "./LoadingView";
import PageContainer from "../pages/PageContainer";
import ProfileView from "../pages/ProfileView";
import Feedback from "./feedback/Feedback";
import FeedbackBoard from "../pages/FeedbackBoard";
import TournamentPage from "../pages/tournament/TournamentPage";
import TournamentCreatePage from "../pages/tournament/TournamentCreatePage";
import TournamentLobbyPage from "../pages/tournament/TournamentLobbyPage";
import HomePage from "../pages/home/HomePage";
import GameTime from "../pages/GameTime";

const Login = lazy(() => import("../pages/Login"));
const LeaderboardPage = lazy(() => import("../pages/LeaderboardPage"));
const RoomCreate = lazy(() => import("../pages/RoomCreate"));
const RoomCreatePractice = lazy(() => import("../pages/RoomCreatePractice"));
const RoomInvite = lazy(() => import("../pages/RoomInvite"));
const RoomJoin = lazy(() => import("../pages/RoomJoin"));
const Game = lazy(() => import("../pages/Game"));
const ResponsiveAppBar = lazy(() => import("./AppNavBar"));
const FooterBar = lazy(() => import("./FooterBar"));
const TermsOfService = lazy(() => import("../pages/TermsOfService"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));

const AppRouter = () => {
  return (
    <Routes>
      <Route
        element={
          <div>
            <Suspense fallback={<LoadingView />}>
              <ResponsiveAppBar />
              <Outlet />
              <ConnectionStatus />
              <Feedback />
              <FooterBar />
            </Suspense>
          </div>
        }
      >
        <Route
          path="/leaderboard"
          element={
            <Suspense fallback={<LoadingView />}>
              <LeaderboardPage />
            </Suspense>
          }
        />
        <Route
          path="/room/create"
          element={
            <Suspense fallback={<LoadingView />}>
              <RoomCreate />
            </Suspense>
          }
        />
        <Route
          path="/room/practice"
          element={
            <Suspense fallback={<LoadingView />}>
              <RoomCreatePractice />
            </Suspense>
          }
        />
        <Route
          path="/room/:roomId"
          element={
            <Suspense fallback={<LoadingView />}>
              <RoomInvite />
            </Suspense>
          }
        />
        <Route
          path="/room"
          element={
            <Suspense fallback={<LoadingView />}>
              <RoomJoin />
            </Suspense>
          }
        />
        <Route
          path="/tournament"
          element={
            <Suspense fallback={<LoadingView />}>
              <TournamentPage />
            </Suspense>
          }
        />
        <Route
          path="/tournament/create"
          element={
            <Suspense fallback={<LoadingView />}>
              <TournamentCreatePage />
            </Suspense>
          }
        />
        <Route
          path="/tournament/:tournamentId/lobby"
          element={
            <Suspense fallback={<LoadingView />}>
              <TournamentLobbyPage />
            </Suspense>
          }
        />
        <Route
          path="/terms-of-service"
          element={
            <PageContainer>
              <TermsOfService />
            </PageContainer>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <PageContainer>
              <PrivacyPolicy />
            </PageContainer>
          }
        />
        <Route path="/feedback" element={<FeedbackBoard />} />
        <Route path="/profile" element={<ProfileView />} />
        <Route path="/dashboard" element={<HomePage />} />
      </Route>
      <Route
        path="/"
        element={
          <Suspense fallback={<LoadingView />}>
            <Login />
          </Suspense>
        }
      />
      <Route path="/verify" element={<VerifyEmail />} />
      <Route path="/:roomId/game" element={<Game />} />
      <Route path="/gameTime" element={<GameTime />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRouter;
