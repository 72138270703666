import { createSlice } from "@reduxjs/toolkit";

const MAX_MESSAGES = 50

export const connInitialState = {
  connected: false,
  messages: []
};

const connSlice = createSlice({
  name: "connection",
  initialState: connInitialState,
  reducers: {
    connect: (state) => {
      state.connected = true;
    },
    disconnect: (state) => {
      state.connected = false;
    },
    pushMessage: (state, action) => {
      state.messages.unshift(action.payload)
      if (state.messages.length > MAX_MESSAGES) {
        state.messages.length = MAX_MESSAGES;
      }
    }
  },
});

export const connReducer = connSlice.reducer;
export const { connect, disconnect, pushMessage } = connSlice.actions;
export default connSlice;
