import { createRequest } from "../utils/requestUtils";

export const searchUser = (params) => {
  return createRequest()
    .get('user/search', { params })
    .then((res) => res.data)
}

export const getUserFulfilledEvent = (userId, type) => {
  return createRequest()
    .get(`user/${userId}/fulfilled-events?type=${type}`)
    .then((res) => res.data)
}

export const getUserFulfilledEventByHash = (hash) => {
  return createRequest()
    .get(`user/event/${hash}`)
    .then((res) => res.data)
}

export const getUserPendingEvent = (userId, type) => {
  return createRequest()
    .get(`user/${userId}/pending-events?type=${type}`)
    .then((res) => res.data)
}

export const deleteUser = (userId) => {
  return createRequest()
    .delete(`user/${userId}`)
    .then((res) => res.data)
}