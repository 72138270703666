import { useState, useEffect } from "react";

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState();

  function checkWindowSize() {
    const {innerWidth:width, innerHeight:height} = window;
    setScreenSize({width, height})
  }

  function addOrientationListener() {
    checkWindowSize()
    window.addEventListener("resize", checkWindowSize);
  }

  useEffect(() => {
    addOrientationListener();
    return () =>
      window.removeEventListener("resize", checkWindowSize);
  }, []);

  return screenSize;
};

export default useScreenSize;