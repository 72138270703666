import { Box, Checkbox, Typography } from "@mui/material";
import { Controller } from "react-hook-form";

const FormCheckbox = ({
  control,
  name,
  label,
  viewMode = false,
  icons = {},
  typographySx = {},
  boxSx = {},
  ...props
}) => {
  const ActiveIconComponent = icons?.active?.img ? (
    <img
      src={icons.active.img.src}
      alt={icons.active.img.alt ?? ""}
      style={{ width: "30px", height: "30px" }}
    />
  ) : undefined;
  const InactiveIconComponent = icons?.inactive?.img ? (
    <img
      src={icons.inactive.img.src}
      alt={icons.inactive.img.alt ?? ""}
      style={{ width: "30px", height: "30px" }}
    />
  ) : undefined;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box
          display={"flex"}
          gap={1}
          width={"100%"}
          alignItems={"center"}
          sx={{ ...boxSx }}
        >
          <Checkbox
            onChange={(ev) => field.onChange(ev.target.checked)}
            checked={field.value}
            {...(InactiveIconComponent ? { icon: InactiveIconComponent } : {})}
            {...(ActiveIconComponent
              ? { checkedIcon: ActiveIconComponent }
              : {})}
            disabled={props.disabled || viewMode}
            {...props}
          />
          <Typography sx={{ ...typographySx }}> {label} </Typography>
        </Box>
      )}
    />
  );
};

export default FormCheckbox;
