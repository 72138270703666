import _ from "lodash";

export function isBase64(str) {
  const base64Regex = /^([a-zA-Z0-9+/]{4})*([a-zA-Z0-9+/]{4}|[a-zA-Z0-9+/]{3}=|[a-zA-Z0-9+/]{2}==)$/;
  return base64Regex.test(str);
}

export function doesUrlMatch(patternUrl, targetUrl) {

  // patternUrl = "/room/:roomId/"
  // targetUrl = "/room/23/"

  // Replace any URL parameter (e.g., :roomId) with a regular expression (\w+)
  const patternWithRegex = patternUrl.replace(/:[^/]+/, "\\w+"); // "/room/:roomId/" => "/room/\w+/"
  
  // Remove trailing slashes from the pattern
  const patternWithoutTrailingSlash = patternWithRegex.replace(/[/]{1}$/, ""); // "/room/\w+/" => "/room/\w+"
  
  // Remove trailing slashes from the target URL
  const targetUrlWithoutTrailingSlash = targetUrl.replace(/[/]{1}$/, ""); // "/room/23/" => "/room/23"
  
  // Create a RegExp object with the modified pattern
  const urlRegex = new RegExp("^" + patternWithoutTrailingSlash + "$"); // "/room/\w+" => /^\/room\/\w+$/
  
  // Test if the target URL matches the pattern
  return urlRegex.test(targetUrlWithoutTrailingSlash);
}

export const buildSrc = (userImg, userImgB64) => {
  let src;
  if (userImgB64) {
    const isOld = userImg?.includes('egg')
    src = isOld ? `data:image/png;base64,${userImgB64}` : userImg
  } else {
    src = userImg
  }
  return src
}
const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

export const formatCurrency = (number) => currencyFormatter.format(number).slice(1,-3)

export const formatName = (name) => _.startCase(name.split("_").join(" "))

export const capitalizeString = (str) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`