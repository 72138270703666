import { createSlice } from "@reduxjs/toolkit";

export const userInitialState = {
  name: "",
  userId: null,
  sessionId: null,
  profileId: null,
  verified: false,
  data: {}
};

const userSlice = createSlice({
  name: "user",
  initialState: userInitialState,
  reducers: {
    updateUser: (state, action) => {
      const updateData = action.payload
      return { ...state, ...updateData };
    },
    resetUser: () => {
      localStorage.clear();
      return userInitialState;
    },
  },
});

export const userReducer = userSlice.reducer;
export const { updateUser, resetUser } = userSlice.actions;
export default userSlice;
