import VerificationSendEmail from "../components/verification/VerificationSendEmail";
import VerificationSent from "../components/verification/VerificationSent";
import VerificationSuccesful from "../components/verification/VerificationSuccesful";
import VerificationUnsuccesful from "../components/verification/VerificationUnsuccesful";
import VerificationBenefits from "../components/verification/VerificationBenefits";
import VerificationReturningUser from "../components/verification/VerificationReturningUser";
import Unverified from "../components/dialogs/Unverified";
import InsufficientCoins from "../components/dialogs/InsufficientCoins";
import AppIntro from "../components/AppIntro";
import VerificationSocial from "../components/verification/VerificationSocial";
import DeleteAccount from "../pages/DeleteAccount";
import GameOver from "../components/game/GameOver";
import GameVictory from "../components/game/GameVictory";
import PlayersWaiting from "../components/playersWaiting/PlayersWaiting";
import RoomPlayersReady from "../components/RoomPlayersReady";

const registry = {
  VERIFICATION_SEND_EMAIL: VerificationSendEmail,
  VERIFICATION_SENT: VerificationSent,
  VERIFICATION_SOCIAL: VerificationSocial,
  VERIFICATION_SUCCESFUL: VerificationSuccesful,
  VERIFICATION_UNSUCCESFUL: VerificationUnsuccesful,
  VERIFICATION_BENEFITS: VerificationBenefits,
  VERIFICATION_RETURNING_USER: VerificationReturningUser,
  UNVERIFIED: Unverified,
  INSUFFICIENT_COINS: InsufficientCoins,
  APP_INTRO: AppIntro,
  DELETE_ACCOUNT: DeleteAccount,
  GAME_OVER: GameOver,
  GAME_VICTORY: GameVictory,
  MATCH_WAIT_LIST: PlayersWaiting,
  ROOM_PLAYERS_READY: RoomPlayersReady,
};

export default registry;
