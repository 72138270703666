import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { popDialog } from "../../slices/dialogSlice";

const findWinnerFromTally = (tally) =>
  Object.keys(tally ?? {}).find((player) => tally[player]?.win >= 3);

const GameVictory = () => {
  const matchWinner = useSelector(({ room }) =>
    findWinnerFromTally(room?.tally)
  );
  const winnerUser = useSelector(({ room }) => room?.users?.[matchWinner]);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(popDialog());
  };

  return (
    <Box
      width={"100vw"}
      height={"100vh"}
      overflow={"hidden"}
      style={winnerUser?.reactions?.win ? {} : {
        backgroundImage: `url(${winnerUser?.avatar})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className={winnerUser?.reactions?.win ? "video-container": ""}
      pb={2}
    >
      {winnerUser?.reactions?.win && <video playsInline
        autoPlay
        muted
        loop
        className="background-video"
        style={{zIndex: 0, filter: "none"}}
        >
        <source src={winnerUser?.reactions?.win}/>
      </video>}
      <Button onClick={handleClose} variant="contained" size="small" style={{position: "absolute", right: 10, bottom: 20}}>
        {" "}
        close{" "}
      </Button>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography
          variant="h1"
          sx={{
            textShadow:
              "5px 0 #000, -5px 0 #000, 0 5px #000, 0 -5px #000, 5px 5px #000, -5px -5px #000, 5px -5px #000, -5px 5px #000",
            fontWeight: 600,
            sm: {
              fontSize: "3rem",
            },
            md: {
              fontSize: "5rem",
            },
            zIndex: 1,
          }}
        >
          WINNER
        </Typography>
        <Typography
          variant="h3"
          sx={{
            mb: 2,
            backgroundColor: "rgba(0,0,0,.9)",
            fontSize: "2rem",
            padding: "10px 20px",
            sm: {
              paddingLeft: "1rem",
              paddingRight: "1rem",
              fontSize: "1rem"
            },
            md: {
              fontSize: "2rem"
            },
            zIndex: 1,
          }}
        >
          {winnerUser?.name}
        </Typography>
      </Box>
    </Box>
  );
};

export default GameVictory;
