import { Select } from "@mui/material";

const customVariants = ["bigfoot"];
const customSx = {
  bigfoot: {
    "& .MuiInputBase-root": {
      letterSpacing: "1rem",
    },
    "& .MuiSelect-select": {
      fontSize: { xs: "3rem", sm: "4rem" },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      letterSpacing: 0,
    },
    "& .MuiSelect-icon": {
      transform: "scale(2)",
      right: "2rem",
    },
    "& .MuiInputLabel-root": {
      fontSize: "2rem",
      left: "2rem",
      top: "-0.9rem",
      "@media (max-width:380px)": {
        fontSize: "1.3rem",
      },
    },
  },
};

const CustomSelect = (props) => {
  const { variant, sx, ...restProps } = props;
  const isCustomVariant = !!variant && customVariants.includes(variant);

  return (
    <Select
      {...restProps}
      variant={isCustomVariant ? "outlined" : variant}
      sx={[...(isCustomVariant ? [customSx[variant]] : []), sx]}
    >
      {props.children}
    </Select>
  );
};

export default CustomSelect;
