import MWForm from "../form/MWForm";

const MatchBody = ({ isInAdvancedOptions, config, onSubmit, onChange }) => {
  const content = {
    items: [
      {
        type: "group",
        items: config?.options,
        hidden: isInAdvancedOptions,
      },
      {
        type: "group",
        items: config?.advancedOptions,
        hidden: !isInAdvancedOptions,
      },
    ],
  };

  return <MWForm content={content} onSubmit={onSubmit} onChange={onChange} />;
};

export default MatchBody;
