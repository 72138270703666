import { Children, cloneElement, useEffect, useState } from "react";

// mui
import MuiAccordion from "@mui/material/Accordion";
import Typography from "@mui/material/Typography";

// icons
import ExpandMore from "@mui/icons-material/ExpandMore";

// components
import LegendSummary from "./LegendSummary";
import { AccordionDetails, AccordionSummary } from "@mui/material";

const PALETTE = ['#3C3F41', '#2C2F33', '#1E2123', '#121416', '#060809'];
const DARK_PALETTE = ['#4E5D6E', '#3C4C5E', '#2A3A4D', '#18293D', '#06182C'];

const emptyFn = () => {}

const Legend = (props) => {
	const { icon, imgSrc, label, svg, description, level = 0, parentChange, expandFromParent, component, children, setDialogExpandedChild = emptyFn } = props;
	const [expanded, setExpanded] = useState(false);
	const [itemExpanded, setItemExpanded] = useState(null);

	const handleChange = () => {
		setExpanded((e) => !e);
	};

	useEffect(() => {
		if (expandFromParent) {
			setDialogExpandedChild({ icon, imgSrc, label, svg, component })
		} else {
			setDialogExpandedChild(null)
		}
	}, [expandFromParent, component, icon, imgSrc, label, setDialogExpandedChild, svg])

	const childChange = (childId) => (ev, expanded) => {
		setItemExpanded(expanded ? childId : null);
	};

	return (
		<MuiAccordion square expanded={expandFromParent || expanded} onChange={parentChange || handleChange} sx={{
			boxShadow: "none",
			"&:not(:last-child)": {
				borderBottom: 0,
			},
			"&:before": {
				display: "none",
			},
		}}>
			<AccordionSummary
				sx={{
					backgroundColor: PALETTE[level],
					marginBottom: -1,
					// height: "20px",
				}}
				expandIcon={<ExpandMore color="inherit" sx={{ color: ({ level }) => DARK_PALETTE[level] ?? DARK_PALETTE[DARK_PALETTE.length - 1] }} />}
			>
				<LegendSummary icon={icon} svg={svg} imgSrc={imgSrc} label={label} />
			</AccordionSummary>
			<AccordionDetails sx={{
				backgroundColor: PALETTE[level + 1] ?? "white", padding: 2,
				display: "flex",
				flexDirection: "column",
			}}>
				{description && <Typography sx={{
					// marginBottom: 20,
					paddingLeft: "8.88%",
				}}> {description} </Typography>}
				{component}
				{Children.map(children, (child) =>
					cloneElement(child, {
						...child.props,
						level: level + 1,
						parentChange: childChange(child.props.label),
						expandFromParent: itemExpanded === child.props.label,
					})
				)}
			</AccordionDetails>
		</MuiAccordion>
	);
};

export default Legend;
