import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import {
  Box,
  Button, Container, TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";

import { sendReturningVerification } from "../../services/profileServices";
import { popDialog, pushDialog } from "../../slices/dialogSlice";

const VerificationReturningUser = () => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    register,
    handleSubmit,
    formState: { errors, },
    setError
  } = useForm({
    mode: "onSubmit"
  });

  const emailValidation = {
    required: 'Email is required',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: 'Invalid email address',
    },
  };

  const handleSendEmail = async (data) => {
    try {
      const { token } = await sendReturningVerification({ userEmail: data.email?.toLowerCase(), socialToken: null })
      dispatch(popDialog());
      dispatch(
        pushDialog({
          id: "VERIFICATION_SENT",
          props: {
            userEmail: data.email?.toLowerCase(),
            token,
            returningUser: true
          },
          options: {
            disableBackdropClick: true
          }
        })
      );
    } catch (err) {
      setError('email', { type: "custom", message: "Couldn't send verification email" })
    }

  };

  return (
    <Container maxWidth="sm">
      <Box
        py={5}
        px={isMobile ? 1 : 5}
        display="flex"
        flexDirection={"column"}
        gap={"1rem"}
        justifyContent={"center"}
      >
        <Typography variant={"h4"} align="center">
          Let me in!
        </Typography>
        <Typography variant={"body1"} align="center">
          Please enter the email address associated with your account
        </Typography>
        <TextField
          label="Your email"
          {...register('email', emailValidation)}
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        <Button variant="contained" onClick={handleSubmit(handleSendEmail)}>
          send
        </Button>
      </Box>
    </Container>
  );
};

export default VerificationReturningUser;
