import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { LinearProgress, useMediaQuery } from "@mui/material";

import { resetGame } from "../../slices/gameSlice";
import { resetRoom } from "../../slices/roomSlice";
import GameResult from "../GameResult";
import { useWorker } from "../WorkerContext";
import CoinCounter from "../CoinCounter";
import CoinConfetti from "../CoinConfetti";
import Countdown from "../Countdown";
import { popDialog } from "../../slices/dialogSlice";
import { resetTournament } from "../../slices/tournamentSlice";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";
import { useEffect, useState } from "react";

const difficultyMultipler = {
  easy: 2,
  medium: 1.7,
  hard: 1.3,
  insane: 1,
};

const countPlayAgain = (players) => {
  const playersArray = Object.values(players);
  const filteredPlayers = playersArray.filter((player) => player.again);
  const allPlayAgain = filteredPlayers.length === playersArray.length;
  return {
    current: filteredPlayers.length,
    total: playersArray.length,
    allPlayAgain,
  };
};

const GameOver = ({ gameCopy }) => {
  const socketWorker = useWorker();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [dialogOpen, setDialogOpen] = useState(false);

  const user = useSelector(({ user }) => user);
  const roomName = useSelector(({ room }) => room.id);
  const tournamentName = useSelector(({ tournament }) => tournament.id);
  const tournamentRoom = useSelector(({ room }) => room.tournamentRoom);
  const deathmatch = useSelector(({ room }) => room?.options?.deathmatch);
  const matchFinished = useSelector(({ room }) =>
    Object.values(room?.tally ?? {}).some((player) => player.win >= 3)
  );
  const roundCount = useSelector(({ room }) => room.roundCount);
  const game = useSelector(({ game }) => game);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const playAgainCount = countPlayAgain(game.players);
  const disableOnLowUsers = (playAgainCount.total < 2) && !tournamentRoom
  const userIsWinner = game.winners.includes(user.name);
  const userPlayAgain = !!game?.players?.[user?.name]?.again;
  const gameTied = game.winners.length > 1;


  const handlePlayAgain = () => {
    socketWorker.postMessage({
      type: "GAME_PLAY_AGAIN",
      payload: { roomName, userName: user.name },
    });
  };

  const handleContinueTournament = () => {
    dispatch(popDialog());
    dispatch(resetGame());
    if (tournamentRoom && matchFinished) {
      dispatch(resetRoom());
    }
    navigate(`/tournament/${tournamentName}/lobby`, { replace: true });
    socketWorker.postMessage({
      type: "TOURNAMENT_CONTINUE",
      payload: { roomName, tournamentName },
    });
  };

  const handleExitGameFromTournament = () => {
    setDialogOpen(true);
  };

  const handleExitGame = (event, reason) => {
    socketWorker.postMessage({
      type: "GAME_LEAVE",
      payload: { roomName, userName: user.name },
    });

    dispatch(resetGame());
    dispatch(resetRoom());
    dispatch(resetTournament());
    navigate("/room", { replace: true });

    dispatch(popDialog());
  };

  const getEndResultText = () => {
    if (!userIsWinner) {
      return { text: "You Lost" };
    } else if (gameTied) {
      return { text: "Tied" };
    } else {
      return { text: "You Won", icon: "🏆" };
    }
  };

  return (
    <Box>
      <DialogTitle>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography>GAME OVER</Typography>
          <Typography>Played {roundCount}</Typography>
        </Box>
      </DialogTitle>
      <Box align={"center"} px={isMobile ? 2 : 4}>
        <Typography
          variant={isMobile ? "h3" : "h2"}
          sx={{ paddingTop: "1rem" }}
        >
          {getEndResultText().icon}
        </Typography>
        <Typography variant={isMobile ? "h3" : "h2"}>
          {getEndResultText().text}
        </Typography>

        {userIsWinner && (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            columnGap={2}
          >
            <Typography>Coins won:</Typography>
            <CoinCounter
              initialCount={0}
              targetCount={user?.data?.wallet?.coins || 0}
              variant="coins"
              differenceOnly={true}
            />
          </Box>
        )}
        <GameResult game={gameCopy} />
      </Box>
      <DialogActions
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      >
        <Button
          onClick={
            tournamentRoom ? handleExitGameFromTournament : handleExitGame
          }
          variant="contained"
          fullWidth
          color="secondary"
          sx={deathmatch ? { backgroundColor: "black", color: "white" } : {}}
        >
          {tournamentRoom ? "Forfeit" : "Exit"}
        </Button>
        <Button
          onClick={
            tournamentRoom && matchFinished
              ? handleContinueTournament
              : handlePlayAgain
          }
          disabled={userPlayAgain || disableOnLowUsers}
          variant="contained"
          sx={
            deathmatch
              ? {
                  backgroundColor: "crimson",
                  color: matchFinished ? "black" : "white",
                }
              : {}
          }
          fullWidth
        >
          {deathmatch ? (
            matchFinished ? (
              tournamentRoom ? (
                "CONTINUE"
              ) : (
                "REMATCH"
              )
            ) : (
              <>
                💀 <Countdown action={handlePlayAgain} startingAt={5} />
              </>
            )
          ) : (
            "AGAIN!"
          )}
        </Button>
      </DialogActions>
      {deathmatch && !matchFinished ? null : (
        <Typography textAlign="center" p={2}>
          {`${matchFinished ? "REMATCH" : "PLAY AGAIN"}: ${
            playAgainCount.current
          }/${playAgainCount.total}`}
        </Typography>
      )}
      {userIsWinner && <CoinConfetti duration={5000} />}
      <ConfirmationDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        onAccept={handleExitGame}
      >
        <Box>
          <Typography>
            Warning: Exiting the match now will result in an instant loss in the
            tournament. Do you still wish to exit?
          </Typography>
        </Box>
      </ConfirmationDialog>
    </Box>
  );
};

export default GameOver;
