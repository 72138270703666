import { Box, Button, Paper, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { popDialog } from "../../slices/dialogSlice";
import { useNavigate } from "react-router-dom";

const InsufficientCoins = ({ coinsRequired }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onPlayClick = () => {
    dispatch(popDialog());
    navigate("/room", { replace: true })
  };

  const handleClose = () => {
    dispatch(popDialog());
  }

  return (
    <>
      <Box p={2}>
        <Box display="flex" justifyContent="center" columnGap={1}>
          <Typography variant="h4" component="h4" align="center" sx={{ mb: 2 }}>
            Not enough coins
          </Typography>
          <img src={`/assets/img/legend/subscription/stack-of-coin-6673804-5526420.png`} alt="coins"  width={"50px"} height={"50px"}/>
        </Box>
        <Paper sx={{ mb: 2 }}>
          <Box p={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <Box>
              <Typography variant="h6">
                <b>You need at least {`${coinsRequired}`} coins for that! </b>
                Try to win more games so you can stack up in coins, good luck!
              </Typography>
            </Box>
          </Box>
        </Paper>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={onPlayClick}
          sx={{ mt: 3 }}
        >
          Play!
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={handleClose}
          sx={{mt: 1}}
        >
          Maybe later
        </Button>
      </Box>
    </>

  );
}

export default InsufficientCoins