import axios from "axios";
import { createRequest } from "../utils/requestUtils";

export const sendVerification = (data) => {
	return createRequest()
		.post(`profiles/verify-email`, data)
		.then((res) => res.data)
};

export const sendReturningVerification = (data) => {
	return createRequest()
		.post(`profiles/verify-email-returning`, data)
		.then((res) => res.data)
};

export const saveInvitationCode = (data) => {
	return createRequest()
		.post(`profiles/invitation-code`, data)
		.then((res) => res.data)
};

export const rewardInviterPlayer = (data) => {
	return createRequest()
		.post(`profiles/reward-inviter`, data)
		.then((res) => res.data)
};

export const checkProfileExists = (params) => {
	return createRequest()
		.get('profiles/exists', { params })
		.then((res) => res.data)
}

export const verifyToken = (params) => {
	return createRequest()
		.get('profiles/verify', { params })
		.then((res) => res.data)
}

export const checkTokenExists = (params) => {
	return createRequest()
		.get('profiles/token-exists', { params })
		.then((res) => res.data)
}

export const checkEmailExists = (params) => {
	return createRequest()
		.get('profiles/email-exists', { params })
		.then((res) => res.data)
}

export const searchProfile = (params) => {
	return createRequest()
		.get('profiles/search', { params })
		.then((res) => res.data)
}

export const getProfile = async (username) => {
	try {
		const url = process.env.REACT_APP_WEBSOCKET_URL ?? `http://${window.location.hostname}:3001`;
		const response = await axios.get(url + `/profiles/lookup?username=${username}`)
		return response?.data
	} catch (err) {
	}
}

export const generateProfileImage = (params) => {
	return createRequest()
		.get('profiles/generate-image', { params })
		.then((res) => res.data)
}

export const generateProfileImageVideo = (params) => {
	return createRequest()
		.get('profiles/generate-video', { params })
		.then((res) => res.data)
}

export const changeAvatar = (profileId, data) => {
	return createRequest()
		.put(`profiles/${profileId}/avatar`, data)
		.then((res) => res.data)
};

export const updateProfile = (profileId, data) => {
	return createRequest()
		.put(`profiles/${profileId}`, data)
		.then((res) => res.data)
};

const exportServices = {
	getProfile
}

export default exportServices;
