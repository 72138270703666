import { useEffect } from "react";
import { useForm } from "react-hook-form";
import FormSwitch from "./FormSwitch";
import FormSelect from "./FormSelect";
import FormSlider from "./FormSlider";
import FormTextField from "./FormTextField";
import FormCheckbox from "./FormCheckbox";
import FormButton from "./FormButton";
import FormGroup from "./FormGroup";

const generateRandomGroupKey = () => {
 const randomNumber = Math.floor(Math.random() * 10000)
 return `G${randomNumber}`
}

const MWForm = ({ content, onSubmit, onChange }) => {
  const defaultValuesIterator = (items, keyPrefix = "") => {
    let defaultValues = {};
    items?.forEach((item) => {
      const itemKey = `${keyPrefix ? (keyPrefix + ".") : ""}${item.key}`;
      const hasItems = item?.items?.length;
      if (hasItems) {
        defaultValues = {
          ...defaultValues,
          ...defaultValuesIterator(item?.items, item?.key),
        };
      } else {
        defaultValues[itemKey] = item?.default;
      }
    });
    return defaultValues;
    //eslint-disable-next-line
  };

  const {
    control,
    watch,
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
    defaultValues: defaultValuesIterator(content.items),
  });

  useEffect(() => {
    if (typeof onChange !== "function") return;
    const subscription = watch((value, { name, type }) => {
      if (type === "change") {        
        onChange(value, name);
      }
    });
    return () => subscription.unsubscribe();
  }, [onChange, watch]);

  const builderComponents = {
    switch: FormSwitch,
    select: FormSelect,
    slider: FormSlider,
    text: FormTextField,
    checkbox: FormCheckbox,
    button: FormButton,
  };

  const getBuilder = (itemType) => (itemKey, item) => {
    const { key, type, ...rest } = item;
    const props = { ...rest };
    if (type === "button" && item?.actionType === "submit") {
      props.onSubmit = handleSubmit?.(onSubmit);
    }

    const Component = builderComponents[type];
    if (itemType === "group")
      return (
        <FormGroup key={itemKey || generateRandomGroupKey()} hidden={item?.hidden} sx={item.sx}>
          {item.items?.length && itemsIterator(item.items, itemKey)}
        </FormGroup>
      );
    return (
      <Component key={itemKey} name={itemKey} control={control} {...props} />
    );
  };

  const itemsIterator = (items, keyPrefix = "") => {
    const builtItems = [];
    items.forEach((item) => {
      let builder = getBuilder(item.type);
      if (!builder) builder = getBuilder("unsupported");
      const itemKey = `${keyPrefix ? (keyPrefix + ".") : ""}${item.key ?? ""}`;
      const isGroup = item?.items;
      const hasItems = item?.items?.length;
      if (!isGroup || hasItems) builtItems.push(builder(itemKey, item));
    });
    return builtItems;
    //eslint-disable-next-line
  };

  return <>{content && itemsIterator(content.items)}</>;
};

export default MWForm;
