import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Container, Icon, Typography } from "@mui/material";

import { popDialog, pushDialog } from "../../slices/dialogSlice";
import { incrementDataRevision } from "../../slices/revisionSlice";
import { useNavigate } from "react-router-dom";
import CoinConfetti from "../CoinConfetti";

const COIN_DROP_DURATION = 1000;

const VerificationSuccesful = ({
  userEmail = "user@emailaddresss.com",
  returningUser = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dontShowHelp = useSelector(
    ({ user }) => user?.data?.preferences?.dontShowHelp
  );

  const handleDone = () => {
    dispatch(popDialog());
    !dontShowHelp && dispatch(pushDialog({ id: "APP_INTRO" }));
  };

  // trigger userRevision
  useEffect(() => {
    dispatch(incrementDataRevision({ event: "userRevision" }));
    // eslint-disable-next-line
  }, []);

  const handleViewProfile = () => {
    dispatch(popDialog());
    navigate("/profile", { replace: true });
  };

  return (
    <Container maxWidth="sm">
      <Box
        p={5}
        display="flex"
        flexDirection={"column"}
        gap={"1rem"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Typography variant={"h4"} align="center">
          Verification succesful
        </Typography>
        {!returningUser && (
          <Typography textAlign={"center"} px={4} sx={{ textOverflow: "wrap" }}>
            <span style={{ fontSize: "smaller", display: "block" }}>
              {userEmail}{" "}
            </span>{" "}
            {`is verified!`}
          </Typography>
        )}
        <Icon fontSize="large"> done </Icon>
        {returningUser ? (
          <Button variant="bigfoot" onClick={handleDone}>
            START
          </Button>
        ) : (
          <Button variant="bigfoot" onClick={handleViewProfile}>
            GET MY AVATAR!!
          </Button>
        )}
        {!returningUser && <CoinConfetti duration={COIN_DROP_DURATION} />}
      </Box>
    </Container>
  );
};

export default VerificationSuccesful;
