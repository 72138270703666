const feedbackPalette = {
  "feedback-NEW_IDEA": {
    main: "#febe00",
    contrastText: "#000",
  },
  "feedback-IMPROVEMENT": {
    main: "#3cb043",
    contrastText: "#fff",
  },
  "feedback-PROBLEM": {
    main: "#b80f0a",
    contrastText: "#fff",
  },
}

export default feedbackPalette