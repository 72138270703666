import {
  Box,
  Button, Container,
  Icon, Typography
} from "@mui/material";
import { sendReturningVerification, sendVerification } from "../../services/profileServices";
import { useDispatch, useSelector } from "react-redux";
import { popDialog, pushDialog } from "../../slices/dialogSlice";

const VerificationUnsuccesful = ({ userEmail = "user@emailaddresss.com", returningUser = false }) => {
  const dispatch = useDispatch()
  const username = useSelector(({ user }) => user.name)

  const handleResend = async () => {
    try {
      const { token } = returningUser ? await sendReturningVerification({ userEmail }) : await sendVerification({ userEmail, username })
      dispatch(popDialog());
      dispatch(
        pushDialog({
          id: "VERIFICATION_SENT",
          props: {
            userEmail,
            token,
            returningUser
          },
          options: {
            disableBackdropClick: true
          }
        })
      );
    } catch (err) {
    }
  }

  const handleSkip = () => {
    dispatch(popDialog());
  };

  return (
    <Container maxWidth="sm">
      <Box
        p={5}
        display="flex"
        flexDirection={"column"}
        gap={"1rem"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Typography variant={"h4"} align="center">
          Verification unsuccesful
        </Typography>
        <Typography> {`${userEmail} not verified`}</Typography>
        <Icon fontSize="large"> close </Icon>
        {/* <AutomaticTryAgain /> */}
        <Button variant="contained" onClick={handleResend}>Re-send link</Button>
        <Typography
          sx={{
            textDecoration: "underline",
            cursor: "pointer",
            "&:hover": {
              textDecoration: "none",
              color: "primary.main",
            },
          }}
          onClick={handleSkip}
        >
          Skip verification{" "}
        </Typography>
      </Box>
    </Container>
  );
};

export default VerificationUnsuccesful;
